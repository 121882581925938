import loadingReducer                               from "../pages/loading/redux_loading_feature";

import crudReducer                                  from "../components/crud/redux_crud_feature";

import headerReducer                                from "../components/_header/redux_header_feature";
import pathReducer                                  from "../components/path/redux_path_feature";
import pageCoreReducer                              from "../components/page_core/redux_page_core_feature";

import userProfilReducer                            from "../pages/profil/redux_user_profil_feature";
import accountReducer                               from "../pages/profil/redux_account_feature";
import docReducer                                   from "../pages/doc/redux_doc_feature";


// - sokeord_redux_breaking


const rootReducer                                   = {
    loading:        loadingReducer,

    crud:           crudReducer,

    header:         headerReducer,
    path:           pathReducer,
    page_core:      pageCoreReducer,

    user_profil:    userProfilReducer,
    account:        accountReducer,
    doc:            docReducer,
};


export default rootReducer;
