/* ****************************************** */
/* String */
/* ****************************************** */

const blank_str                             = "";


export {

    blank_str

};