/* ****************************************** */
/* Redux */
/* ****************************************** */

const redux_loading                         = "loading";
const redux_crud                            = "crud";
const redux_path                            = "path";
const redux_header                          = "header";
const redux_footer                          = "footer";
const redux_page_core                       = "page_core";
const redux_user_profil                     = "user_profil";
const redux_user_profil_thunk               = "user_profil_thunk";
const redux_account                         = "account";
const redux_doc                             = "doc";


export {

    redux_loading,
    redux_crud,
    redux_path,
    redux_header,
    redux_footer,
    redux_page_core,
    redux_user_profil,
    redux_user_profil_thunk,
    redux_account,
    redux_doc,

};