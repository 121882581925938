import {
    lay_unit_px_per_rem,

    lay_xx,
    lay_xp,
    lay_yy,
    lay_yp,
}                                                   from "../konstanter/lay";


const lay_is_ref                                    = (ref, typ) => { // DEV: Sjekk at alle er riktige, spesielt offsetWidth (xx) vs ? (xp)
    if (typ === lay_xx) {
        if (ref.current) {return ref.current.offsetWidth};
    };
    if (typ === lay_xp) {
        if (ref.current) {return ref.current.offsetLeft};
    };
    if (typ === lay_yy) {
        if (ref.current) {return ref.current.clientHeight};
    };
    if (typ === lay_yp) {
        if (ref.current) {return ref.current.offsetTop};
    };
    return 0
};

const lay_rem_til_px                                = (val) => {return (val * lay_unit_px_per_rem);}; // Ubrukt


export {
    lay_is_ref,
    lay_rem_til_px
};
