import {
    useCallback,
    useContext,
    useEffect,
    useState
}                                                   from "react";
import {
    useDispatch
}                                                   from "react-redux";

import {
    setHeaderDropdownIsFalseGlobal
}                                                   from "../../components/_header/redux_header_feature";
import Logo                                         from "../../components/pic/logo/logo";


import AuthContext                                  from "../../context/auth_context";
import {
    version_navn
}                                                   from "../../innstillinger/innstillinger";

import {
    doc_ingen_blokk
}                                                   from "../../konstanter/pages";
import {
    delay_footer_load
}                                                   from "../../konstanter/time";
import {
    json_root_pk,

    route_root,

    route_footer,
}                                                   from "../../konstanter/url";

import {
    setDoNavigate
}                                                   from "../../pages/doc/redux_doc_feature";

import {
    get_txt,
    versjon,
    footer_copyright,
    footer_about_tittel,
    footer_kurs_tittel,
    footer_terms_tittel
}                                                   from "../../txt/txt";


const Footer                                        = () => {

    // Context
    const {human_lang}                              = useContext(AuthContext);

    // Redux
    const dispatch                                  = useDispatch();

    // Load
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_is_load_done_load_async               = useCallback( () => {setTimeout(() => {setIsLoadDone(true);}, delay_footer_load);}, []);

    // Header
    const set_header_dropdown_is_false_global       = useCallback( () => {dispatch(setHeaderDropdownIsFalseGlobal())}, [dispatch]);

    // Page core
    const page_core_reset                           = () => { // console.log("footer.js")

        // Header dropdown
        set_header_dropdown_is_false_global();

    };

    // Doc
    const set_do_navigate                           = (arr) => {dispatch(setDoNavigate(arr))};

    // Click
    const click_footer_link                         = (doc_slug) => {

        // Page core > Reset
        page_core_reset();

        // Do_navigating
        set_do_navigate([
            doc_ingen_blokk,
            route_footer,
            doc_slug]);
    };
    const click_logo                                = () => {

        // Do_navigating > Root
        set_do_navigate([
            doc_ingen_blokk,
            route_root,
            json_root_pk]);
    };

    // JSX
    const _footer_link                              = (tit, doc_slug) => {
        return (
            <div
                className="txt_liten_link"
                onClick={() => click_footer_link(doc_slug)}>
                    {tit}
            </div>
        )
    };

    // useEffect
    useEffect( () => {

        // Load
        set_is_load_done_load_async();

        // Dep
    }, [set_is_load_done_load_async]);

    // Return
    return (
        <>
        {is_load_done &&

            <div
                className="footer_mobile"
                onClick={() => page_core_reset()}>

                    {/* Strek */}
                    <div className="strek_footer"></div>

                    {/* Links */}
                    <div className="footer_link_wrapper">
                        {_footer_link(get_txt(footer_about_tittel, human_lang), "footer_about")}
                        {_footer_link(get_txt(footer_terms_tittel, human_lang), "footer_terms")}
                        {_footer_link(get_txt(footer_kurs_tittel, human_lang), "footer_kurs")}

                    </div>

                    {/* Logo / Copyright */}
                    <div className="footer_logo_copyright">

                        {/* Logo */}
                        <Logo
                            cls      = "logo_footer"
                            on_click = {() => click_logo()}/>

                        {/* Copyright */}
                        <div className="txt_liten_grey">
                            {get_txt(footer_copyright, human_lang)}
                        </div>
                    </div>

                    {/* Versjon */}
                    <div className="txt_liten_grey">
                        {get_txt(versjon, human_lang)} {version_navn}
                    </div>

            </div>}
        </>
    )

};


export default Footer;
