/* ****************************************** */
/* Pages */
/* ****************************************** */

// Reg
const reg_sign_in_email_confirm_typ             = "reg_sign_in_email_confirm_typ";
const reg_sign_in_glemt_passord_typ             = "reg_sign_in_glemt_passord_typ";
const reg_sign_in_glemt_passord_confirm_typ     = "reg_sign_in_glemt_passord_confirm_typ";
const reg_sign_in_typ                           = "reg_sign_in_typ";
const reg_sign_up_typ                           = "reg_sign_up_typ";

// Profil > Profil set > Account
const profil_set_public_profil_item         = "item_public_profil";
const profil_set_account_item               = "item_account";
const profil_set_lang_item                  = "item_lang";
const profil_set_notifications_item         = "item_notifications";
const profil_set_email_item                 = "item_email";

// Doc
const doc_ingen_blokk                       = 0;
const doc_ingen_blokk_router                = -1;
const doc_branch_rem_per_level_indent       = 1;
const doc_branch_arrow_start_level          = 0;
const doc_branch_doc_txt_debug              = false;
const doc_branch_blokk_txt_debug            = false;

// Admin
const admin_user_username                   = "user.username";
const admin_user_id                         = "user.user_id";
const admin_user_id_felt                    = "user_id_felt";
const admin_img_profil                      = "img_profil";
const admin_username_current                = "username_current";
const admin_full_name                       = "full_name";
const admin_email_public                    = "email_public";
const admin_bio_public                      = "bio_public";
const admin_url_public                      = "url_public";
const admin_company_public                  = "company_public";
const admin_poeng                           = "poeng";
const admin_human_lang                      = "human_lang";


export {

    // Reg
    reg_sign_in_email_confirm_typ,
    reg_sign_in_glemt_passord_typ,
    reg_sign_in_glemt_passord_confirm_typ,
    reg_sign_in_typ,
    reg_sign_up_typ,

    // Profil set > Account
    profil_set_public_profil_item,
    profil_set_account_item,
    profil_set_lang_item,
    profil_set_notifications_item,
    profil_set_email_item,

    // Doc
    doc_ingen_blokk,
    doc_ingen_blokk_router,
    doc_branch_rem_per_level_indent,
    doc_branch_arrow_start_level,
    doc_branch_doc_txt_debug,
    doc_branch_blokk_txt_debug,

    // Admin
    admin_user_username,
    admin_user_id,
    admin_user_id_felt,
    admin_img_profil,
    admin_username_current,
    admin_full_name,
    admin_email_public,
    admin_bio_public,
    admin_url_public,
    admin_company_public,
    admin_poeng,
    admin_human_lang,

};