import {
    createSlice
}                                                   from "@reduxjs/toolkit";

import {
    redux_loading
}                                                   from "../../konstanter/redux";

// ************************************************ ...

const initialState                                  = {

    initx_is_fast_load:                 false,
    initx_loading_done:                 false,
    initx_fetch_lock:                   false,

    is_modal_loading_done:              true,
    is_loading_create_user_done:        true,

    is_spinner:                         false,

};


const loadingSlice                                  = createSlice({
    name:                               redux_loading,
    initialState:                       initialState,
    reducers: {

        // Initx
        setInitxIsFastLoad(state) {

            // DEV > Forklaring > av hva som skjer
            state.initx_is_fast_load = true;

        },
        setInitxLoadingDone(state) {

            // DEV > Forklaring > av hva som skjer
            state.initx_loading_done = true;

        },
        setInitxBackendFetchLock(state) {

            // DEV > Forklaring > av hva som skjer
            state.initx_fetch_lock = true;

        },

        // Modal loading
        setIsModalLoading(state, action) {

            // DEV > Forklaring > av hva som skjer
            state.is_modal_loading_done = action.payload;

        },
        setIsModLoading(state) {

            // DEV > Forklaring > av hva som skjer
            state.is_modal_loading_done = false;

        },
        setIsModalLoadingDone(state) {

            // DEV > Forklaring > av hva som skjer
            state.is_modal_loading_done = true;

        },

        // Loading create user
        setIsLoadingCreateUser(state) {

            // DEV > Forklaring > av hva som skjer
            state.is_loading_create_user_done = false;

        },
        setIsLoadingCreateUserDone(state) {

            // DEV > Forklaring > av hva som skjer
            state.is_loading_create_user_done = true;

        },

        // Spinner
        setIsSpinner(state, action) {

            state.is_spinner = action.payload

        }
    }
});


export const {

    // Initx
    setInitxIsFastLoad,
    setInitxLoadingDone,
    setInitxBackendFetchLock,

    // Modal loading
    setIsModalLoading,
    setIsModLoading,
    setIsModalLoadingDone,

    // Loading create user
    setIsLoadingCreateUser,
    setIsLoadingCreateUserDone,

    // Spinner
    setIsSpinner

}                                                   = loadingSlice.actions;


export default loadingSlice.reducer;
