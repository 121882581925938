import {
    grp_txt_norm_beige_hoverable,
    grp_txt_norm_grey,
    grp_txt_norm_link,
    grp_txt_medium_grey_bold,
    grp_txt_stor_beige,
    grp_txt_felt_modal_beige,

    grp_icon_liten_24_16,
    grp_icon_norm_24_24,
    grp_icon_norm_48_12,
    grp_icon_norm_48_16,
    grp_icon_norm_48_20,
    grp_icon_tittel_norm,
    grp_icon_forside_stor,

    grp_alert_red_abs,
    grp_alert_green_100,
    grp_alert_red_100,
    grp_alert_green_reg,
    grp_alert_red_reg,

    grp_img_sub_bilde_profil,
    grp_img_mini,
    grp_img_mikro,
    grp_img_liten,
    grp_img_norm,
    grp_img_medium,
    grp_img_stor,

    grp_btn_green,
    grp_btn_green_100,
    grp_btn_red,
    grp_btn_red_100,
    grp_btn_red_trans_ugyldig,
    grp_btn_red_trans_100_ikke_gyldig,
    grp_btn_blue_100,
    grp_btn_grey,
    grp_btn_grey_100,
    grp_btn_trans_white,

    grp_input_felt,
    grp_input_display_none,

    grp_felt_100,
    grp_felt_center,
    grp_felt_left,
}                                                   from "../konstanter/grp";


// Txt
const is_txt_grp                                    = (grp) => {
    if (grp === grp_txt_norm_beige_hoverable)       {return "txt_norm_beige_2_hoverable"};
    if (grp === grp_txt_norm_grey)                  {return "txt_norm_grey"};

    if (grp === grp_txt_norm_link)                  {return "txt_norm_link"};

    if (grp === grp_txt_medium_grey_bold)           {return "txt_medium_grey_bold"};

    if (grp === grp_txt_stor_beige)                 {return "txt_stor_beige_1"};

    if (grp === grp_txt_felt_modal_beige)           {return "txt_modal_beige_1"}; // cropper_modal
};
const is_txt_alert_grp                              = (grp) => {

    // - Usikker på om grp_alert_red_abs brukes,
    //   is_txt_alert_grp() kalles i alert, men ikke med dette arg
    // - Denne variabelen er også i både is_icon_alert_grp()
    //   og nedenfor is_alert_grp (rydd opp i dette / gjør det forståelig)


    if (grp === grp_alert_red_abs)                 {return "txt_liten_white"}; // "txt_norm_beige_2"


    return "txt_liten_beige_1"

};

// Icon
const is_icon_grp                                   = (icon_cls, icon_size) => {
    if (icon_size === grp_icon_liten_24_16)         {return `${icon_cls}_${icon_size}`;};    // 24_16  -> svg: 24px,  css: 16px (1.00rem)

    if (icon_size === grp_icon_norm_24_24)          {return `${icon_cls}_${icon_size}`;};    // 24_24  -> svg: 24px,  css: 24px (1.50rem)

    if (icon_size === grp_icon_norm_48_12)          {return `${icon_cls}_${icon_size}`;};    // 48_12  -> svg: 48px,  css: 12px (0.75rem)
    if (icon_size === grp_icon_norm_48_16)          {return `${icon_cls}_${icon_size}`;};    // 48_16  -> svg: 48px,  css: 16px (1.00rem)
    if (icon_size === grp_icon_norm_48_20)          {return `${icon_cls}_${icon_size}`;};    // 48_20  -> svg: 48px,  css: 20px (1.25rem)

    if (icon_size === grp_icon_tittel_norm)         {return `${icon_cls}_${icon_size}`;};    // 48_24  -> svg: 48px,  css: 24px (1.50rem)

    if (icon_size === grp_icon_forside_stor)        {return `${icon_cls}_${icon_size}`;};    // 48_24  -> svg: 48px,  css: 24px (1.50rem)

};
const is_icon_alert_grp                             = (grp) => {

    // Returnerer arr > Breaker i alert.js hvis ikke definert grp_altert_xyz

    if (grp === grp_alert_red_abs)                  {return ["", ""]};

    if (grp === grp_alert_green_100)                {return ["icon_close_green",    grp_icon_liten_24_16]};
    if (grp === grp_alert_red_100)                  {return ["icon_close_red",      grp_icon_liten_24_16]};

    if (grp === grp_alert_green_reg)                {return ["icon_close_green",    grp_icon_liten_24_16]};
    if (grp === grp_alert_red_reg)                  {return ["icon_close_red",      grp_icon_liten_24_16]};

};

// Img
const is_img_grp                                    = (grp, subgrp) => {
    if (subgrp === grp_img_sub_bilde_profil) {
        if (grp === grp_img_mini)                   {return "bilde_profil_mini"};
        if (grp === grp_img_mikro)                  {return "bilde_profil_mikro"};
        if (grp === grp_img_liten)                  {return "bilde_profil_liten"};
        if (grp === grp_img_norm)                   {return "bilde_profil_norm"};
        if (grp === grp_img_medium)                 {return "bilde_profil_medium"};
        if (grp === grp_img_stor)                   {return "bilde_profil_stor"};
    };
};

// Btn
const is_btn_grp                                    = (grp) => {

    if (grp === grp_btn_green)                      {return "btn_green_resp"};
    if (grp === grp_btn_green_100)                  {return "btn_green_100"};

    if (grp === grp_btn_red)                        {return "btn_red"};
    if (grp === grp_btn_red_100)                    {return "btn_red_100"};
    if (grp === grp_btn_red_trans_ugyldig)          {return "btn_red_trans_ugyldig"};
    if (grp === grp_btn_red_trans_100_ikke_gyldig)  {return "btn_red_trans_100_ugyldig"};

    if (grp === grp_btn_blue_100)                   {return "btn_blue_100"};

    if (grp === grp_btn_grey)                       {return "btn_grey"};
    if (grp === grp_btn_grey_100)                   {return "btn_grey_100"};

    if (grp === grp_btn_trans_white)                {return "btn_trans_white"};

};

// Input
const is_input_grp                                  = (grp) => {
    if (grp === grp_input_felt)                     {return "felt_input"};

    if (grp === grp_input_display_none)             {return "display_none"};

};

// Alert
const is_alert_grp                                  = (grp) => {

    if (grp === grp_alert_red_abs)                  {return "flex_alert_red_abs"};

    if (grp === grp_alert_green_100)                {return "flex_alert_green"};
    if (grp === grp_alert_red_100)                  {return "flex_alert_red"};

    if (grp === grp_alert_green_reg)                {return "flex_alert_green_reg"};
    if (grp === grp_alert_red_reg)                  {return "flex_alert_red_reg"};
};

// Felt
const is_felt_grp                                   = (grp) => {
    if (grp === grp_felt_100)                   {return "flex_felt_100"}; // reg, profil_set, quest_create
};
const is_felt_btn_wrapper_grp                       = (grp) => {
    if (grp === grp_felt_center)                    {return "flex_felt_center"};

    if (grp === grp_felt_left)                      {return "flex_felt_L"};
};

export {
    is_txt_grp,
    is_txt_alert_grp,
    is_icon_grp,
    is_icon_alert_grp,
    is_btn_grp,
    is_img_grp,
    is_input_grp,
    is_alert_grp,
    is_felt_grp,
    is_felt_btn_wrapper_grp
};
