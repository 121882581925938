import {
    txt_regex_url_clean
}                                                   from "../konstanter/components";


const tekst_format_json                             = (str) => {

    // Debug > Pre > str
    console.log("Pre > str :: ", str)

    // Vari
    const _str = str
    const _raw = JSON.stringify(_str)

    // Debug > Post > Format
    console.log("Pst > Stringified :: ", _raw)

    // Return
    return _str
};

const tekst_letter_cap_first                        = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const tekst_line_counter                            = (yy_str) => { // Ubrukt
    // return yy_str.split(/\r\n|\r|\n/).length
    return (yy_str.match(/\n/g) || '').length + 1 // + 1
};

const _sifferify                                    = (index) => { // Ubrukt

    const _str = String(index);
    if (_str.length === 2) {return `  ${index}`};
    if (_str.length === 3) {return ` ${index}`};
    if (_str.length === 4) {return `${index}`};
    return ""
};

const tekst_debug_statement                         = (typ, tid, filnavn, fn, txt) => { // Ubrukt

    // Init
    let _typ        = "";
    let _tid        = "";
    let _filnavn    = "";
    let _fn         = "";
    let _txt        = "";

    if (typ)       {_typ            = `${typ} `};
    if (tid)       {_tid            = `> ${_sifferify(tid)} `};
    if (filnavn)   {_filnavn        = `> ${filnavn} `};
    if (fn)        {_fn             = `> ${fn} `};
    if (txt)       {_txt            = `> ${txt} `};

    const _ret = `${_typ}${_tid}${_filnavn}${_fn}${_txt}`

    return _ret
};

const url_clean                                     = (url) => {
    // Clean url > Fjerner double slash etter f.eks. https://www.autogazer.com/#/quest/Quest_importere_funksjoner_fra_andre_filer
    const _clean_url    = url.replace(txt_regex_url_clean, "$1/");

    return _clean_url
};

const fil_ext_dotless                               = (fil_ext) => {

    // F.eks. ".html" > "html"
    const _fil_ext_dotless = fil_ext.slice(1, fil_ext.length);

    return _fil_ext_dotless

};


export {
    tekst_format_json,
    tekst_letter_cap_first,
    tekst_line_counter,
    tekst_debug_statement,
    url_clean,
    fil_ext_dotless,
};
