import Icon                                         from "../../components/pic/icon/icon";

import {
    btn_typ_col,
    btn_typ_txt_link,
    btn_typ_input,
}                                                   from "../../konstanter/components";

import {
    is_btn_grp
}                                                   from "../../utils/group_util";


const Btn                                           = (props) => {

    // Return
    return (
        <>
        {props.btn_typ === btn_typ_col &&
            <div
                className={is_btn_grp(props.btn_grp)}
                onClick={() => props.on_click()}>
                    <>

                    {/* Med icon */}
                    {props.icon_cls &&
                        <div className="flex_row_LC_G05">
                            <Icon
                                id           = {props.id}
                                icon_size    = {props.icon_size}
                                icon_cls     = {props.icon_cls}
                                icon_wrapper = {props.icon_wrapper}/>
                            {props.txt}
                        </div>}

                    {/* Uten icon */}
                    {!props.icon_cls &&
                        props.txt}
                    </>
            </div>}

        {props.btn_typ === btn_typ_txt_link &&
            <div
                className={is_btn_grp(props.btn_grp)}
                onClick={() => props.on_click()}>
                    {props.txt}
            </div>}
        {props.btn_typ === btn_typ_input &&
            <div
                className={is_btn_grp(props.btn_grp)}
                onClick={() => props.on_click()}>
                    {props.txt}
            </div>}

        </>
    )
};


export default Btn;

