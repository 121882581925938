import {
    route_doc
}                                                   from "../konstanter/url";

import {
    lay_unit_px_per_rem,
}                                                   from "../konstanter/lay";


/* ****************************************** */
/* System */
/* ****************************************** */

// Version
const version_navn                      = "23.11.10-dfff";      // Forrige: "23.6.17", Midlertig løsning

// Strict mode
const is_strict_mode                    = true;         // true: Development, false: Production

// Developer / Production > Test-email > contact@matematikkas.com
const _is_development                   = 0;                            // Søkeord > _is_development, 0: Production, 1: Devloper
const is_redirect                       = false;                        // true: Redirect, false: nettside
const redirect_link                     = "https://www.webhuset.no";    // https://www.webhuset.no, "https://www.google.com"

// Password
const is_pass_hidden                    = true;

// Human lang
const _set_eng                          = "_eng";
const _set_no                           = "_no";
let _human_lang_selected                = _set_no;
let _human_lang_default                 = "";
if (_human_lang_selected === _set_eng)  {_human_lang_default = _set_eng};   // Select variabel
if (_human_lang_selected === _set_no)   {_human_lang_default = _set_no};    // Select variabel
const human_lang_default_hardkodet      = _set_no;                          // -"- > Hardkodet fordi context ikke er definert enda

// Nettsiden
const nettside                          = "programmering.no";
const selskap_as                        = "MAS"; // Matematikk AS


/* ****************************************** */
/* Master */
/* ****************************************** */

// Emo / Txt
const _master_emo_txt_override_all          = false;    // Path + branch
const _master_emo_txt_override_all_is_emo   = false;    // true > emo, false > txt
const _master_path_emo_txt_override         = false;    // Path
const _master_path_emo_txt_override_is_emo  = false;    // true > emo, false > txt


/* ****************************************** */
/* Components */
/* ****************************************** */

// Header
const header_yy_hardkodet                       = lay_unit_px_per_rem * 4;
const header_is_yy_hardkodet                    = true;
const header_landing_item_in_header             = false;
const header_footer_item_in_header              = false;
const header_developer_item_in_header           = false;
const header_developer_url_frontend             = route_doc;
const header_developer_url_frontend_json_slug   = "python";
const header_mal_item_in_header                 = false;

// Path
let path_emo_override_root                      = true;
let path_emo_override_profil_disp               = true;
let path_emo_override_profil_set                = true;
let path_emo_override_admin                     = true;
const path_disp_root_solo                       = true;     // true: Disp "Landing / Home" path har kun en tittel, false: Motsatt, ikke disp alene, men kun når path har flere titler


/* ****************************************** */
/* Pages */
/* ****************************************** */

// Reg
const reg_signup_email_developer_override_value     = "a365b24@gmail.com";
const reg_signin_page_root                          = "reg_signin_page_root";
const reg_signin_page_profil_disp                   = "reg_signin_page_profil_disp";
const reg_signin_page                               = reg_signin_page_profil_disp; // Bruk egendefinerte alternativer over fordi root og singin har forskjellig page_url-system
const reg_signin_loading_spinner                    = true;

// Profil
const user_profil_list_show_admins                  = true;
const user_profil_list_show_admins_list             = [
    "adm", // Ikke slett / behold dette navnet > Laget fra Djano
    // "bob6",
    "def",
    // "life",
    // "lin",
    // "matematikk"
];
const user_profil_list_show_antall                  = false;

// Doc
let doc_branch_emo_override                         = false;    // true > emo, false > txt
const doc_crud_icons_show                           = true;



/* ****************************************** */
/* Utils */
/* ****************************************** */

const bg_util_img_default                   = false;


/* ****************************************** */
/* Master logic */
/* ****************************************** */

// Emo / Txt > Path
if (_master_path_emo_txt_override) {

    // All > Txt
    if (_master_path_emo_txt_override_is_emo === false) {

        // Emo
        path_emo_override_root              = false;
        path_emo_override_profil_disp       = false;
        path_emo_override_profil_set        = false;
        path_emo_override_admin             = false;
    };

    // All > Emo
    if (_master_path_emo_txt_override_is_emo === true) {

        // Emo
        path_emo_override_root              = true;
        path_emo_override_profil_disp       = true;
        path_emo_override_profil_set        = true;
        path_emo_override_admin             = true;
    };

};

// Emo / Txt > All
if (_master_emo_txt_override_all) {

    // All > Txt
    if (_master_emo_txt_override_all_is_emo === false) {

        // Path
        path_emo_override_root              = false;
        path_emo_override_profil_disp       = false;
        path_emo_override_profil_set        = false;
        path_emo_override_admin             = false;

        // Branch
        doc_branch_emo_override             = false;
    };

    // All > Emo
    if (_master_emo_txt_override_all_is_emo === true) {

        // Path
        path_emo_override_root              = true;
        path_emo_override_profil_disp       = true;
        path_emo_override_profil_set        = true;
        path_emo_override_admin             = true;

        // Branch
        doc_branch_emo_override             = true;
    };

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    // System
    version_navn,
    _is_development,
    is_redirect,
    redirect_link,
    is_strict_mode,
    is_pass_hidden,
    _human_lang_default,
    human_lang_default_hardkodet,
    nettside,
    selskap_as,

    // Header
    header_yy_hardkodet,
    header_is_yy_hardkodet,
    header_landing_item_in_header,
    header_footer_item_in_header,
    header_developer_item_in_header,
    header_developer_url_frontend,
    header_developer_url_frontend_json_slug,
    header_mal_item_in_header,

    // Path
    path_emo_override_root,
    path_emo_override_profil_disp,
    path_emo_override_profil_set,
    path_emo_override_admin,
    path_disp_root_solo,

    // Reg
    reg_signup_email_developer_override_value,

    // Doc
    doc_branch_emo_override,
    doc_crud_icons_show,

    // Settings
    reg_signin_page_root,
    reg_signin_page_profil_disp,
    reg_signin_page,
    reg_signin_loading_spinner,

    // Profil
    user_profil_list_show_admins,
    user_profil_list_show_admins_list,
    user_profil_list_show_antall,

    // Util > bg_util.js
    bg_util_img_default

};


/* ****************************************** */
/* End */
/* ****************************************** */
