// ************************************************ ...

import {
    useEffect,
    useCallback
}                                                   from "react";
import {
    useDispatch,
    useSelector
}                                                   from "react-redux";

import {
    setPageCoreYP
}                                                   from "../../components/page_core/redux_page_core_feature";
import {
    setPathYP
}                                                   from "../../components/path/redux_path_feature";

import {
    redux_path,
    redux_header,
}                                                   from "../../konstanter/redux";

import {
    setHeaderDropdownIsFalseGlobal,
    setHeaderDocItemMatOpenReset,
    setHeaderYYReset
}                                                   from "./redux_header_feature";


const DoHeaderDropdownFalse                         = () => {

    // Redux
    const dispatch                                  = useDispatch();

    // Path
    const {path_yy}                                 = useSelector((state) => {return state[redux_path]});
    const set_path_yp                               = useCallback( (val) => {dispatch(setPathYP(val))}, [dispatch]);

    // Header
    const {header_yy}                               = useSelector((state) => {return state[redux_header]});
    const {header_yy_init_val}                      = useSelector((state) => {return state[redux_header]});
    const {header_dropdown_is_down_global}          = useSelector((state) => {return state[redux_header]});
    const set_header_dropdown_is_false_global       = useCallback( () => {dispatch(setHeaderDropdownIsFalseGlobal())}, [dispatch]);
    const set_header_doc_item_mat_open_reset        = useCallback( () => {dispatch(setHeaderDocItemMatOpenReset())}, [dispatch]);
    const set_header_yy_reset                       = useCallback( () => {dispatch(setHeaderYYReset())}, [dispatch]);
    const _set_header_dropdown_is_false             = useCallback( () => { // console.log("> header_dropdown.js > _set_header_dropdown_is_false")

        /* - Collapser header_dropdown > Resetter relevante yy og yp  */

        // Header > Dropdown > False
        set_header_dropdown_is_false_global()

        // Header > Dropdown > Par el > Collapse all
        set_header_doc_item_mat_open_reset()

        // Header > YY > Reset raskt til init (state lagger) > Gjør at useEffekt kalles 2 ganger
        set_header_yy_reset()

        // Path > YP > Endres når header_dropdown kollapser
        set_path_yp(header_yy_init_val);

        // Dep
    }, [set_header_doc_item_mat_open_reset,
        set_header_dropdown_is_false_global,
        set_path_yp,
        set_header_yy_reset,
        header_yy_init_val]);

    // Page core
    const set_page_core_yp                          = useCallback( (val) => {dispatch(setPageCoreYP(val))}, [dispatch]);
    const page_core_yp_calc                         = useCallback( () => {

        /* - Note */
        /*   - Egen funksjon som regner ut page_core yp */
        /*   - Kan variere uavhengig av header_dropdowns collapse / expanded */

        // Page core > YP > Regn ut fra header_yy- og path_yy-redux-variablene
        const _page_core_yp
        = header_yy
        + path_yy

        // Page core > YP > Set
        set_page_core_yp(_page_core_yp)

        // Dep
    }, [set_page_core_yp,
        header_yy,
        path_yy])

    // useEffect
    useEffect( () => { // console.log("> header_dropdown_false.js > useEffect")

        // Header YY > Path YP > Executer kun hvis false > Dropdown er collapsed
        if (header_dropdown_is_down_global === false) {
            _set_header_dropdown_is_false();
        };

        // Page core > YP > Må regnes ut her, uavhengig av om header_dropdown_is_down_global endres
        page_core_yp_calc();

        // Dep
    }, [page_core_yp_calc,
        _set_header_dropdown_is_false,
        header_dropdown_is_down_global]);

    // Return
    return (<>{/* <div className="tekst">Debug :: </div> */}</>)

};


export default DoHeaderDropdownFalse;
