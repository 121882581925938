/* ****************************************** */
/* Components */
/* ****************************************** */

// Header
const header_desktop_par_extra_marg             = 0.25;         // rem
const header_banner_yy                          = 4.0 * 16.0;   // 6 > Debug (ekstra linje under meny), 5 > Eksakt
const header_gap_min                            = 0.0;
const header_gap_max                            = 32.0;
const header_dropdown_mobile_hele_fixed         = "header_dropdown_mobile_hele_fixed";
const header_dropdown_mobile_hele_fixed_limit   = "header_dropdown_mobile_hele_fixed_limit";
const header_dropdown_mobile_hele_dynamisk      = "header_dropdown_mobile_hele_dynamisk";
const header_dropdown_mobile_hele_dynamisk_none = "header_dropdown_mobile_hele_dynamisk_none";

// Btn
const btn_typ_col                           = "btn_typ_col";
const btn_typ_txt_link                      = "btn_typ_txt_link";
const btn_typ_input                         = "btn_typ_input";

// CRUD
const crud_typ_doc_btn                      = "crud_typ_doc_btn";
const crud_typ_doc_icon_arr                 = "crud_typ_doc_icon_arr";
const crud_typ_admin                        = "crud_typ_admin";
const crud_author_init                      = "_author_init";                       // Matasy > Tidligere author_init, beholder foreløpig string-verdien fordi det kanskje switches på denne

// Dropdown
const dropdown_typ_kategori                 = "dropdown_typ_kategori";
const dropdown_cls_absolute_grey            = "_dropdown_cls_absolute_grey";
const dropdown_cls_flexbox_trans            = "_dropdown_cls_flexbox_trans";
const dropdown_liste_typ_checkbox           = "dropdown_liste_typ_checkbox";
const dropdown_liste_typ_liste              = "dropdown_liste_typ_liste";           // Ubrukt
const dropdown_id                           = "filter_typ_tagg_id";                 // Matasy > Tidligere filter_typ_tagg_id
const dropdown_checkbox_typ_custom          = "checkbox_typ_custom";                // Matasy > Tidligere checkbox_typ_custom

// Felt
const felt_info_mode_right                  = "felt_info_mode_right";
const felt_info_mode_sub                    = "felt_info_mode_sub";

// IFrame
const iframe_script_html_prefix             = "data:text/html;charset=utf-8,";      // Alt (data): text/javascript, Matasy > Usikker på hva denne egentlig brukes til, kanskje spille av yt-videoer på nettsiden
const iframe_script_tag_start               = "<script>";                           // Tidliger editor_kode_script_tag_start osv
const iframe_script_tag_end                 = "</script>";
const iframe_script_style_tag_start         = "<style>";
const iframe_script_style_tag_end           = "</style>";

// Input
const input_el_input                        = "input";
const input_el_textarea                     = "textarea";
const input_type_text                       = "text";
const input_type_password                   = "password";
const input_type_file                       = "file";

// Input > Textarea
const input_textarea_min_height             = 2.25;     // rem, DEV: Sjekk xy med css root
const input_textarea_padding                = 0.5;      // rem
const input_textarea_padding_btm            = 0.75;     // rem
const input_textarea_typ_norm               = "_norm";  // norm

// Txt > Human lang
const _txt_human_lang_typ                   = "human_lang"
const _txt_human_lang_tittel                = "Human lang" // Ubrukt > Vises foreløpig ikke

const txt_human_lang_eng                    = "_eng";
const txt_human_lang_no                     = "_no";

const txt_human_lang_typ_arr = [
    {typ: _txt_human_lang_typ, tittel: _txt_human_lang_tittel}
];
const txt_human_lang_arr = [
    {slug: txt_human_lang_no,   tittel: "Norsk",    typ: _txt_human_lang_typ},
    {slug: txt_human_lang_eng,  tittel: "Engelsk",  typ: _txt_human_lang_typ},
];

// Txt > Lengths
const txt_len_max_countdown                 = 5;
const txt_len_info_standard_medium          = 100;
const txt_len_username_max                  = 14;   // Er også hardkodet i reg > txt_regex_username (ikke nødvendig, stoppes JSX-elementet)
const txt_len_email_max                     = 254;  // 254;  // RFC 5321-standard (https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address), ikke... hardkodet i email-regex (se over)
const txt_len_password_min                  = 6;
const txt_len_password_max                  = 20;
const txt_len_url_max                       = 500;
const txt_len_user_profil_bio_max           = 500;

// Txt > Regex
const txt_regex_username                    = /^[a-z][^\W_]{0,14}$/i; // Alternativ: /^[a-zA-Z\-]+$/;
const txt_regex_email                       = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const txt_regex_url_clean                   = /([^:])(\/\/+)/g;


export {

    // Header
    header_desktop_par_extra_marg,
    header_banner_yy,
    header_gap_min,
    header_gap_max,
    header_dropdown_mobile_hele_fixed,
    header_dropdown_mobile_hele_fixed_limit,
    header_dropdown_mobile_hele_dynamisk,
    header_dropdown_mobile_hele_dynamisk_none,

    // Btn
    btn_typ_col,
    btn_typ_txt_link,
    btn_typ_input,

    // CRUD
    crud_typ_doc_btn,
    crud_typ_doc_icon_arr,
    crud_typ_admin,
    crud_author_init,

    // Dropdown
    dropdown_typ_kategori,
    dropdown_cls_absolute_grey,
    dropdown_cls_flexbox_trans,
    dropdown_liste_typ_checkbox,
    dropdown_liste_typ_liste,
    dropdown_id,
    dropdown_checkbox_typ_custom,

    // Felt
    felt_info_mode_right,
    felt_info_mode_sub,

    // IFrame
    iframe_script_html_prefix,
    iframe_script_tag_start,
    iframe_script_tag_end,
    iframe_script_style_tag_start,
    iframe_script_style_tag_end,

    // Input
    input_el_input,
    input_el_textarea,
    input_type_text,
    input_type_password,
    input_type_file,

    // Input > Textarea
    input_textarea_min_height,
    input_textarea_padding,
    input_textarea_padding_btm,
    input_textarea_typ_norm,

    // Txt > Human lang
    txt_human_lang_eng,
    txt_human_lang_no,
    txt_human_lang_typ_arr,
    txt_human_lang_arr,

    // Txt > Lengths
    txt_len_max_countdown,
    txt_len_info_standard_medium,
    txt_len_username_max,
    txt_len_email_max,
    txt_len_password_min,
    txt_len_password_max,
    txt_len_url_max,
    txt_len_user_profil_bio_max,

    // Txt > Regex
    txt_regex_username,
    txt_regex_email,
    txt_regex_url_clean,

};