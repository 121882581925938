import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
}                                                   from "react";
import {
    useDispatch,
    useSelector
}                                                   from "react-redux";

import {
    setHeaderDropdownIsFalseGlobal
}                                                   from "../../components/_header/redux_header_feature";
import {
    setCRUDDocReset
}                                                   from "../../components/crud/redux_crud_feature";
import Icon                                         from "../../components/pic/icon/icon";
import Tittel                                       from "../../components/txt/tittel";


import AuthContext                                  from "../../context/auth_context";

import {
    path_emo_override_root,
    path_emo_override_profil_disp,
    path_emo_override_profil_set,
    path_emo_override_admin,
    path_disp_root_solo
}                                                   from "../../innstillinger/innstillinger";

import {
    grp_txt_norm_grey,
    grp_txt_norm_link,

    grp_icon_norm_48_12,
}                                                   from "../../konstanter/grp";
import {
    http_method_post,

    http_headers_content_type_application_json,
}                                                   from "../../konstanter/http";
import {
    lay_yy
}                                                   from "../../konstanter/lay";
import {
    doc_ingen_blokk
}                                                   from "../../konstanter/pages";
import {
    redux_loading,
    redux_path,
    redux_doc,
}                                                   from "../../konstanter/redux";
import {
    delay_initx_load,

    delay_load,

    delay_ref,
}                                                   from "../../konstanter/time";
import {
    json_root_pk,
    json_landing_pk,

    json_footer_pk,

    json_page_landing,

    json_tittel_root,
    json_tittel_home,

    url_api_doc_pk,

    route_root,

    route_doc,

    route_profil_disp_pk,
}                                                   from "../../konstanter/url";

import {
    setDoNavigate,
    setDocCurrent,
    setDocIndex,
    setDocArr,
}                                                   from "../../pages/doc/redux_doc_feature";
import {
    setIsModLoading,
    setIsModalLoadingDone,
    setIsSpinner,
}                                                   from "../../pages/loading/redux_loading_feature";

import {
    get_txt,
    root_tittel,
    landing_special_case_home_tittel,
    admin_tittel,
    user_profil_tittel,
    user_profil_account_tittel,
    emo_root,
    emo_admin,
    emo_profil_disp,
    emo_profil_set,
}                                                   from "../../txt/txt";

import {
    debug_path_click_path_item_do_navigate,
    debug_path_get_doc
}                                                   from "../../utils/debug_util";
import {
    lay_is_ref
}                                                   from "../../utils/lay_util";
import {
    username_status
}                                                   from "../../utils/obj_util";
import {
    url_clean,
    tekst_debug_statement
}                                                   from "../../utils/tekst_util";

import {
    setPathYY
}                                                   from "./redux_path_feature";


const Path                                          = () => {

    // Context
    const {user, human_lang}                        = useContext(AuthContext);

    // Redux
    const dispatch                                  = useDispatch();

    // Load
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_is_load_done_async                    = useCallback( () => {
        const _set_is_load_done_async = () => {
            setIsLoadDone(true);
        };
        setTimeout(() => {_set_is_load_done_async(delay_load)}, );
    }, []);

    // Loading
    const {initx_is_fast_load}                      = useSelector( (state) => {return state[redux_loading]});
    const {initx_loading_done}                      = useSelector( (state) => {return state[redux_loading]});
    const set_is_mod_loading                        = useCallback( () => {dispatch(setIsModLoading())}, [dispatch]);
    const set_is_modal_loading_done                 = useCallback( () => {dispatch(setIsModalLoadingDone())}, [dispatch]);
    const set_is_spinner                            = useCallback( (val) => {dispatch(setIsSpinner(val))}, [dispatch]);

    // CRUD
    const set_crud_doc_reset                        = useCallback( (arr) => {dispatch(setCRUDDocReset(arr))}, [dispatch]);

    // Header
    const set_header_dropdown_is_false_global       = useCallback( () => {dispatch(setHeaderDropdownIsFalseGlobal())}, [dispatch]);

    // Path
    const {path_is_visible}                         = useSelector( (state) => {return state[redux_path]});
    const {path_yp}                                 = useSelector( (state) => {return state[redux_path]});
    const {path_yy_toggle}                          = useSelector( (state) => {return state[redux_path]});
    const path_ref                                  = useRef(null);
    const set_path_yy                               = useCallback( (val) => {dispatch(setPathYY(val))}, [dispatch]);

    // Page core
    const page_core_reset_standard                  = useCallback( () => {set_header_dropdown_is_false_global()}, [set_header_dropdown_is_false_global])
    const page_core_exit                            = useCallback( () => {page_core_reset_standard()}, [page_core_reset_standard])

    // Doc
    const {doc_slug}                                = useSelector( (store) => {return store[redux_doc]});
    const {doc_arr}                                 = useSelector( (store) => {return store[redux_doc]});
    const {doc_fetch}                               = useSelector( (store) => {return store[redux_doc]});
    const set_doc_current                           = useCallback( (val) => {dispatch(setDocCurrent(val))}, [dispatch]);
    const set_doc_index                             = useCallback( (typ) => {dispatch(setDocIndex(typ))}, [dispatch]);
    const set_doc_arr                               = useCallback( (typ) => {dispatch(setDocArr(typ))}, [dispatch]);
    const set_do_navigate                           = useCallback( (arr) => {dispatch(setDoNavigate(arr))}, [dispatch])

    // Path > Is display
    const _is_disp_tittel_path_switch               = (path) => {

        // Tittel > Hidden
        if (path === 0) {return false};

        // Tittel > Show
        if (path === 1) {return true};
        if (path === 2) {return true};

    };
    const _is_disp_tittel_all_slug_hide             = (slug) => {

        // Footer
        if (slug === json_footer_pk)     {return false};

        // Default > Show
        return true
    };
    const _is_disp_tittel_all_tittel_hide_ubrukt    = (tittel) => {

        // - Note > Ubrukt

        // Template
        if (tittel === "___fyll_inn_tittel__")  {return false};

        if (tittel === "___fyll_inn_tittel__")  {return false};

        // Default > Show
        return true
    };
    const _is_disp_tittel_last_slug_ubrukt          = (slug) => {

        // - Note > Ubrukt

        // Template
        if (slug === "___fyll_inn_slug__")    {return false};

        // Default > Show
        return true
    };
    const _is_disp_tittel_last_tittel_hide_ubrukt   = (tittel) => {

        // - Note > Ubrukt

        // Template
        if (tittel === "___fyll_inn_slug__")    {return false};

        // Default > Show
        return true
    };
    const _is_disp_arrow_hide                       = (center, slug) => {

        // Center
        if (center === slug)                            {return false};

        // Footer
        if (slug === json_footer_pk)                    {return false}

        // Return > true
        return true

    };
    const _is_disp_doc_hide_ubrukt                  = () => {

        // Template
        if (doc_slug === "___fyll_inn_doc__")    {return false};

        // Default >
        return true
    };
    const _is_disp_tittel_slug_icon_ubrukt          = (slug) => {

        // Template
        if(slug === "___fyll_inn_slug__")   {return false}

        // Return
        return true
    };
    const _is_disp_landing_special_case             = () => {

        // Hvis landing special case > Ikke disp "root > "landing / home", men kun "landing / home"
        if (doc_arr[0].slug === json_root_pk &&
        doc_arr[1].slug === json_landing_pk) { return false }

        // Er ikke special case > Standard
        else { return true };

    };
    const _is_disp_root                             = () => {
        if (path_disp_root_solo === true) {return true};

        if (path_disp_root_solo === true) {return false};

    };

    // Txt / Emo > Settings
    const _is_tittel_custom                         = (tittel) => { // console.log("tittel :: ", tittel)

        /* Root > Home */
        if (
            tittel === json_tittel_root) {
                return json_tittel_home}

        /* Default > Ingen custom tittel */
        else {

            // Return > Standard tittel
            return tittel};

    };
    const _is_txt_emo                               = (tittel) => { // console.log("tittel :: ", tittel)

        /* Root / Landing > Emo */
        if (
            tittel === get_txt(root_tittel, human_lang) &&
            path_emo_override_root === true) {
                return emo_root}

        /* Admin > Emo */
        else if (
            tittel === get_txt(admin_tittel, human_lang) &&
            path_emo_override_admin === true) {
                return emo_admin}

        /* Profil disp > Settings */
        else if (
            tittel === get_txt(user_profil_tittel, human_lang) &&
            path_emo_override_profil_disp === true) {
                return emo_profil_disp}

        /* Profil disp > Account > Settings */
        else if (
            tittel === get_txt(user_profil_account_tittel, human_lang) &&
            path_emo_override_profil_set === true) {
                return emo_profil_set}

        /* Default > Tittel */
        else {

            // Hvis tittel er custom > F.eks Root / Hjem
            let _tittel = _is_tittel_custom(tittel) // console.log("_tittel :: ", _tittel)

            // Ingen custom tittel > Standard
            return get_txt(_tittel, human_lang)};

    };

    // Click
    const click_path_item                           = useCallback( (item) => {

        // Loading > Modal > True
        set_is_mod_loading();

        // Page core > Exit
        page_core_exit();

        // Ingen page > Doc
        if (!item.page) {

            // Debug
            if (debug_path_click_path_item_do_navigate) {
                console.log("> path.js > Doc > item.page :: ", item.page)
                console.log("> path.js > Doc > slug      :: ", item.slug)
            };

            // Do_navigating
            set_do_navigate([
                doc_ingen_blokk,
                route_doc,
                item.slug]);
        }

        // Page
        else {

            /* Root / Landing */
            if (item.page === String(json_page_landing)) {

                // Debug
                if (debug_path_click_path_item_do_navigate) {
                    console.log("> path.js > Root > item.page :: ", item.page)
                    console.log("> path.js > Root > slug      :: ", item.slug)
                };

                // Do_navigating
                set_do_navigate([
                    doc_ingen_blokk,
                    route_root,
                    item.slug]);} //

            /* Profil disp > F.eks. bakover fra profil set */
            else if (item.page === String(route_profil_disp_pk)) {

                // Debug
                if (debug_path_click_path_item_do_navigate) {
                    console.log("> path.js > Profil disp > item.page :: ", item.page)
                    console.log("> path.js > Profil disp > slug      :: ", item.slug)
                };

                if (user) {
                    // Do_navigating
                    set_do_navigate([
                        doc_ingen_blokk,
                        `${item.page}${user.username}`,
                        item.slug]);
                } else {
                    // Do_navigating
                    set_do_navigate([
                        doc_ingen_blokk,
                        route_root,
                        item.slug]);
                };
            }

            /* Default > Page > F.eks. fra Quest til QuestList */
            else {

                // Debug
                if (debug_path_click_path_item_do_navigate) {
                    console.log("> path.js > Default > item.page :: ", item.page)
                    console.log("> path.js > Default > slug      :: ", item.slug)
                };

                // Do_navigating
                set_do_navigate([
                    doc_ingen_blokk,
                    item.page,
                    item.slug]);}
        };

    }, [page_core_exit,
        set_do_navigate,
        set_is_mod_loading,
        user]);

    // Path yy
    const set_path_yy_async                         = useCallback( () => { // console.log("set_path_yy_async!")

        // Init
        let _delay = 0;

        // initx_is_fast_load > False > Long delay
        if (initx_is_fast_load === false) {
            _delay = delay_initx_load;
        };

        // initx_is_fast_load > True > Short delay
        if (initx_is_fast_load === true) {
            _delay = delay_ref;
        };

        // Async > Def
        const _get_path_yy_async = () => {
            set_path_yy(lay_is_ref(path_ref, lay_yy));
        };

        // Async > Call
        setTimeout(() => {_get_path_yy_async()}, _delay);

        // Dep
    }, [set_path_yy,
        initx_is_fast_load])

    // Opacity
    const is_opacity_loaded                         = () => {

        // Initialization > Ikke done > 1
        if (initx_loading_done !== false) {
            return 1
        };

        // Initialization > Done > 1
        if (initx_loading_done === true) {
            return 1
        };

    };

    // Fetch > Automatisk
    const fetch_get_doc_item                        = useCallback( (slug) => { // console.log("user :: ", user)

        // Clean url fjerner double slash (/)
        const _url        = url_clean(`${url_api_doc_pk}${slug}/`);

        // Initx
        fetch(_url, {
            method:     http_method_post,
            headers:    http_headers_content_type_application_json,
            body: JSON.stringify({
                "username":         username_status(user)[0],
                "is_guest":         username_status(user)[1],
            })
        })
        .then(res => res.json())
        .then(dat => { // console.log("> fetch_get_doc_item > dat :: ", dat)

            // Debug
            if (debug_path_get_doc) {
                console.log(tekst_debug_statement("Initx", "F000", "path.js", "fetch_get_doc_item()", ""));
            };

            // Center index
            const _center_index = dat[0].center_index

            // CRUD
            const _blokk_arr = dat[_center_index].blokk_arr

            // Page > Doc
            if (_blokk_arr.length > 0) {set_crud_doc_reset(_blokk_arr);}

            // Page > Quest > Bypass
            else {};

            // Doc
            set_doc_current(dat[_center_index]);
            set_doc_arr(dat);
            set_doc_index(_center_index);

            // Modal loading
            set_is_modal_loading_done();

            // Spinner
            set_is_spinner(false);

        })

        // Dep
    }, [set_crud_doc_reset,
        set_doc_arr,
        set_doc_index,
        set_doc_current,
        set_is_modal_loading_done,
        set_is_spinner,
        user]);

    // useEffect
    useEffect( () => {

        // Path yy toggle > Kun for å justere path yy async
        if (path_yy_toggle === true) {} else {};

        // Load > Done > Async
        set_is_load_done_async();

        // Path > YY > Async
        set_path_yy_async();

        // Fetch-mekanismen til do_navigate > True > Fetch
        if (doc_fetch === true) {


            // Fetch > Doc
            fetch_get_doc_item(doc_slug);

        };

        // Dep
    }, [set_is_load_done_async,
        set_path_yy_async,
        fetch_get_doc_item,
        path_yy_toggle,
        doc_slug,
        doc_fetch]);

    // Return
    return (
        <>
        {is_load_done &&
        path_is_visible &&
            <div
                className="flex_path_wrapper"
                style={{
                    top:        `${path_yp}px`,
                    opacity:    `${is_opacity_loaded()}`,
                    // zIndex:     5
                }}>
                    <div
                        ref         = {path_ref}
                        className   = "flex_path">
                        {doc_arr.map((item, index) =>
                            <React.Fragment key={index}>

                                {/* Debug > {console.log("doc_arr :: ", doc_arr)} */}

                                {/* Landing > Special case */}
                                {item.path === 0 &&
                                item.slug === json_landing_pk &&
                                _is_disp_root() &&

                                    <Tittel
                                        txt         = {get_txt(landing_special_case_home_tittel, human_lang)} // "Hjem"
                                        txt_grp={grp_txt_norm_link}
                                        on_click    = {() => click_path_item(item)}/>}

                                {/* All > Alle el fra root > Ikke siste el */}
                                {item.path === 1 &&
                                item.slug !== json_landing_pk &&
                                _is_disp_tittel_all_slug_hide(item.slug) &&
                                _is_disp_tittel_all_tittel_hide_ubrukt(item.tittel) &&

                                    <Tittel
                                        txt         = {_is_txt_emo(item.tittel)}
                                        txt_grp     = {grp_txt_norm_link}
                                        on_click    = {() => click_path_item(item)}/>}

                                {/* Last > Siste el > Ingen link */}
                                {item.path === 2 &&
                                item.slug !== json_landing_pk && // Landing special case
                                _is_disp_tittel_last_slug_ubrukt(item.slug) &&
                                _is_disp_tittel_last_tittel_hide_ubrukt(get_txt(item.tittel, human_lang)) &&

                                    <Tittel
                                        txt_grp     = {grp_txt_norm_grey}
                                        txt         = {_is_txt_emo(item.tittel)}/>}

                                {/* Arrow */}
                                {_is_disp_arrow_hide(item.center, item.slug) &&
                                _is_disp_tittel_path_switch(item.path) &&
                                _is_disp_tittel_slug_icon_ubrukt(item.slug) &&
                                _is_disp_doc_hide_ubrukt() &&
                                item.slug !== json_landing_pk && // Landing special case
                                _is_disp_landing_special_case() &&

                                    <Icon
                                        icon_size   = {grp_icon_norm_48_12}
                                        icon_cls    = "icon_arrow_collapsed_beige_2_hoverless"/>}

                            </React.Fragment>
                        )}
                    </div>
            </div>}
        </>
    )

};


export default Path;
