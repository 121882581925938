/* ****************************************** */
/* Scroll */
/* ****************************************** */

const scroll_item                           = "scroll";
const scroll_smooth                         = "smooth";


export {

    scroll_item,
    scroll_smooth,

};