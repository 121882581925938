import {
    createSlice
}                                                   from "@reduxjs/toolkit";

import {
    redux_page_core
}                                                   from "../../konstanter/redux";


const initialState                                  = {
    page_core_yp:               0,
    scroll_pos_yp:              0,          // Søkeord > Scroll_yp
    scroll_pos_yp_is_done:      false,      // Søkeord > Scroll_yp
};


const pageCoreSlice                                 = createSlice({
    name:               redux_page_core,
    initialState:       initialState,
    reducers: {

        // Page core yp
        setPageCoreYP(state, action) {

            // DEV > Forklar input param
            state.page_core_yp = action.payload;

        },

        // Scroll > Søkeord > Scroll_yp
        setScrollPosYP(state, action) {

            // DEV > Forklar input param
            state.scroll_pos_yp = action.payload;

        },
        setScrollPosYPIsDone(state, action) {

            // DEV > Forklar input param
            state.scroll_pos_yp_is_done = action.payload;

        }

    }
});


export const {

    // Page core yp
    setPageCoreYP,

    // Scroll
    setScrollPosYP,
    setScrollPosYPIsDone

}                                                   = pageCoreSlice.actions;


export default pageCoreSlice.reducer;
