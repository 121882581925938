/* ****************************************** */
/* URL */
/* ****************************************** */

// Backend > JSON > PK
const json_root_pk                                  = "root";
const json_landing_pk                               = "landing";    // Note: Søkord 3: page_core_yp_update -> oppdaterer page_core_yp

const json_admin_pk                                 = "admin";

const json_user_profil_list_pk                      = "user_profil_list"

const json_profil_disp_pk                           = "profil_disp";
const json_profil_set_pk                            = "profil_set";

const json_sign_in_pk                               = "signin";                             // Kun for ikke å få doc-feilmelding
const json_sign_up_pk                               = "signup";                             // funket med signin !!!! PRØV, kun for ikke å få doc-feilmelding

const json_footer_pk                                = "footer";
const json_footer_terms_pk                          = "footer_terms";
const json_footer_kurs_pk                           = "footer_kurs";
const json_footer_about_pk                          = "footer_about";                       // Resten av footer-linkene er satt på pause


// Backend > JSON > Page
const json_page_landing                             = "json_page_landing";                  // Fordi react ikke vil re-loade en side som allerede er loadet, må denne hacken til for å switch mellom root og landing, som begge render den samme siden
const json_page_home_header_shortcut                = "json_page_home_header_shortcut";
const json_page_sign_out                            = "json_page_sign_out";                 // Switch-logikken til root


// Backend > JSON > Tittel > Path
const json_tittel_root                              = "json_tittel_root";                   // Defineres her > Export til _tekst.js for riktig oversettelse
const json_tittel_home                              = "json_tittel_home";                   // -"-, Tittlene brukes i path


// Backend > API > _main
const url_api_mal                                   = "/api/mal/"; // Har ikke frontend-versjon > foreløpig ikke nødvendig

const url_api_db_load                               = "/api/db_load/";
const url_api_db_delete                             = "/api/db_delete/";
const url_api_db_cycle_initialisation               = "/api/db_cycle_initialisation/";
const url_api_db_cycle                              = "/api/db_cycle/";

const url_api_admin_json_back_add                   = "/api/admin_json_back_add/";
const url_api_admin_json_back_remove                = "/api/admin_json_back_remove/";
const url_api_admin_json_back_push                  = "/api/admin_json_back_push/";

const url_api_admin_user_arr_dump_cleanup_bu        = "/api/admin_user_arr_dump_cleanup_bu/";
const url_api_admin_user_arr_dump                   = "/api/admin_user_arr_dump/";
const url_api_admin_user_arr                        = "/api/admin_user_arr/";


// Backend > API > account
const url_api_token_refresh                         = "/api/token/refresh/";
const url_api_token                                 = "/api/token/";

const url_api_developer_send_email_user_confirm     = "/api/developer_send_email_user_confirm/";
const url_api_developer_send_email_glemt_passord    = "/api/developer_send_email_glemt_passord/";

const url_api_user_glemt_passord_sett_nytt_pk       = "/api/user_glemt_passord_sett_nytt/";
const url_api_user_glemt_passord_pk                 = "/api/user_glemt_passord/";

const url_api_user_update_username_pk               = "/api/user_update_username/";
const url_api_user_update_password_pk               = "/api/user_update_password/";

const url_api_user_profil_item_txt                  = "/api/user_profil_item_txt/";
const url_api_user_profil_item                      = "/api/user_profil_item/";
const url_api_user_profil_img_pk                    = "/api/user_profil_img/";
const url_api_user_profil_human_lang_pk             = "/api/user_profil_human_lang/";

const url_api_username_fra_current                  = "/api/username_fra_current/";

const url_api_user_opptatt_username                 = "/api/user_opptatt_username/";
const url_api_user_opptatt_email                    = "/api/user_opptatt_email/";

const url_api_user_create                           = "/api/user_create/";

const url_api_user_profil_list_filter_arr           = "/api/user_profil_list_filter_arr/";


// Backend > API > doc
const url_api_blokk_pk                              = "/api/blokk/";
const url_api_get_doc_slug_pk                       = "/api/get_doc_slug/";
const url_api_doc_header                            = "/api/doc_header/";
const url_api_doc_pk                                = "/api/doc/";


// Frontend > Page route > App.js
const route_root                                    = "/";

const route_mal                                     = "/mal";

const route_admin                                   = "/admin";

const route_doc                                     = "/doc";
const route_doc_router_pk                           = "/doc_router/";

const route_black_screen                            = "/black_screen";

const route_profil_disp_pk                          = "/profil_disp/";  // DEV > Var uten hermetegn, se om det funker på gøy > /profil_disp/;
const route_profil_set                              = "/profil_set";
const route_user_profil_list                        = "/user_profil_list";

const route_sign_in                                 = "/signin";        // _Reg.js > route_sign_in er et dobbelt-arg i set_do_navigate(), vet ikke hvorfor, men det fungerer (og hele reg-siden er litt annerledes konstruert)
const route_sign_in_email_confirm                   = "/signin_email_confirm";

const route_sign_in_glemt_passord                   = "/signin_glemt_passord";
const route_sign_in_glemt_passord_confirm           = "/signin_glemt_passord_confirm";

const route_sign_up                                 = "/signup";

const route_footer                                  = "/footer";


export {

    // Backend > JSON > PK
    json_root_pk,
    json_landing_pk,

    json_admin_pk,

    json_profil_disp_pk,
    json_profil_set_pk,

    json_user_profil_list_pk,

    json_sign_in_pk,
    json_sign_up_pk,

    json_footer_pk,
    json_footer_terms_pk,
    json_footer_kurs_pk,
    json_footer_about_pk,


    // Backend > JSON > Page
    json_page_landing,
    json_page_home_header_shortcut,
    json_page_sign_out,


    // Backend > JSON > Tittel > Path
    json_tittel_root,
    json_tittel_home,


    // Backend > API > _main
    url_api_mal,

    url_api_db_load,
    url_api_db_delete,
    url_api_db_cycle_initialisation,
    url_api_db_cycle,

    url_api_admin_json_back_add,
    url_api_admin_json_back_remove,
    url_api_admin_json_back_push,

    url_api_admin_user_arr_dump_cleanup_bu,
    url_api_admin_user_arr_dump,
    url_api_admin_user_arr,


    // Backend > API > account
    url_api_token_refresh,
    url_api_token,

    url_api_developer_send_email_user_confirm,
    url_api_developer_send_email_glemt_passord,

    url_api_user_glemt_passord_sett_nytt_pk,
    url_api_user_glemt_passord_pk,

    url_api_user_update_username_pk,
    url_api_user_update_password_pk,

    url_api_user_profil_item_txt,
    url_api_user_profil_item,
    url_api_user_profil_img_pk,
    url_api_user_profil_human_lang_pk,

    url_api_username_fra_current,

    url_api_user_opptatt_username,
    url_api_user_opptatt_email,

    url_api_user_create,

    url_api_user_profil_list_filter_arr,


    // Backend > API > doc
    url_api_blokk_pk,
    url_api_get_doc_slug_pk,
    url_api_doc_header,
    url_api_doc_pk,


    // Frontend > Page route > App.js
    route_root,

    route_mal,

    route_admin,

    route_doc,
    route_doc_router_pk,

    route_footer,

    route_black_screen,

    route_profil_disp_pk,
    route_profil_set,
    route_user_profil_list,

    route_sign_in,
    route_sign_in_email_confirm,
    route_sign_in_glemt_passord,
    route_sign_in_glemt_passord_confirm,

    route_sign_up,

};