const Logo                                          = (props) => {

    // Return
    return (
        <div
            className   = {props.cls}
            onClick     = {() => props.on_click()}>
        </div>
    )

};


export default Logo;
