import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef
}                                                   from "react";
import {
    useDispatch,
    useSelector
}                                                   from "react-redux";
import {
    Link
}                                                   from "react-router-dom";


import AuthContext                                  from "../../context/auth_context";

import {
    header_yy_hardkodet,
    header_is_yy_hardkodet,
    header_landing_item_in_header,
    header_footer_item_in_header,
    header_developer_item_in_header,
    header_developer_url_frontend,
    header_developer_url_frontend_json_slug,
    header_mal_item_in_header,
}                                                   from "../../innstillinger/innstillinger";

import {
    header_desktop_par_extra_marg,
    header_banner_yy,
    header_gap_min,
    header_gap_max,
    header_dropdown_mobile_hele_fixed,
    header_dropdown_mobile_hele_fixed_limit,
    header_dropdown_mobile_hele_dynamisk,
    header_dropdown_mobile_hele_dynamisk_none,

    btn_typ_col,
}                                                   from "../../konstanter/components";
import {
    grp_icon_norm_24_24,
    grp_icon_norm_48_12,
    grp_icon_norm_48_20,

    grp_img_sub_bilde_profil,
    grp_img_mikro,

    grp_btn_trans_white,
}                                                   from "../../konstanter/grp";
import {
    http_method_get,

    http_headers_content_type_application_json,
}                                                   from "../../konstanter/http";
import {
    key_ch_arr,
    key_open,

    key_page,
}                                                   from "../../konstanter/key";
import {
    lay_yy,

    lay_window_width_limit_xx_1,
    lay_window_width_limit_xx_2,

    lay_window_resize,
    lay_window_device_view_mobile_1,
    lay_window_device_view_mobile_2,
    lay_window_device_view_desktop,
    lay_window_device_view_no_device,
}                                                   from "../../konstanter/lay";
import {
    doc_ingen_blokk,
}                                                   from "../../konstanter/pages";
import {
    redux_header,
    redux_doc,
}                                                   from "../../konstanter/redux";
import {
    scroll_item,
    scroll_smooth,
}                                                   from "../../konstanter/scroll";
import {
    delay_initx_header_ref,

    delay_arg_0,
    delay_load,
    delay_warning_qf,
    delay_ref,
}                                                   from "../../konstanter/time";
import {
    json_root_pk,

    json_profil_disp_pk,

    json_sign_up_pk,

    json_page_landing,
    json_page_home_header_shortcut,
    json_page_sign_out,

    url_api_doc_header,

    route_root,

    route_mal,

    route_admin,

    route_doc,

    route_footer,

    route_profil_disp_pk,
    route_user_profil_list,

    route_sign_in,

    route_sign_up,
}                                                   from "../../konstanter/url";

import {
    debug_header_doc_header_arr
}                                                   from "../../utils/debug_util";
import {
    lay_is_ref
}                                                   from "../../utils/lay_util";
import {
    url_clean,
    tekst_debug_statement
}                                                   from "../../utils/tekst_util";


import DoNavigate                                   from "../../pages/doc/do_navigate";
import {
    setDoNavigate
}                                                   from "../../pages/doc/redux_doc_feature";
import {
    setIsSpinner
}                                                   from "../../pages/loading/redux_loading_feature";


import Btn                                          from "../../components/btn/btn";
import {
    setPathIsHide,
    setPathYP
}                                                   from "../../components/path/redux_path_feature";
import Icon                                         from "../../components/pic/icon/icon";
import Img                                          from "../../components/pic/img/img";
import Logo                                         from "../../components/pic/logo/logo";


import {
    get_txt,
    landing_tittel,
    header_developer_tittel,
    signup_tittel
}                                                   from "../../txt/txt";


import DoHeaderDropdownFalse                        from "./do_header_dropdown_false";
import {
    setIsHeaderDropdownOmvendtToggle,
    setHeaderDropdownIsFalseGlobal,
    setHeaderDocItemMat,
    setHeaderDocItemMatOpen,
    setHeaderDocItemMatOpenReset,
    setWinTyp,
    setHeaderDropdownMobileHeleR,
    setHeaderYY,
    setHeaderYYInitVal
}                                                   from "./redux_header_feature";


const Header                                        = () => {

    // Context
    const {
        user,
        is_superuser,
        img_profil,
        signout_user,
        human_lang
    }                                               = useContext(AuthContext);

    // Redux
    const dispatch                                  = useDispatch();

    // Load
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_is_load_done_load_async               = useCallback( () => {setTimeout( () => {setIsLoadDone(true);}, delay_load);}, []);

    // Loading
    const set_is_spinner                            = useCallback( (val) => {dispatch(setIsSpinner(val))}, [dispatch]);

    // Header
    const {header_is_visible}                       = useSelector( (state) => {return state[redux_header]});
    const {header_dropdown_is_down_global}          = useSelector( (state) => {return state[redux_header]});
    const {header_yy_init_val}                      = useSelector( (state) => {return state[redux_header]});
    const {header_doc_item_mat}                     = useSelector( (state) => {return state[redux_header]});
    const header_mobile_ref                         = useRef(null);
    const header_desktop_or_dropdown_ref            = useRef(null);
    const set_header_dropdown_is_false_global       = useCallback( ()    => {dispatch(setHeaderDropdownIsFalseGlobal())}, [dispatch]);
    const set_is_header_dropdown_omvendt_toggle     = ()    => {dispatch(setIsHeaderDropdownOmvendtToggle())};
    const set_is_header_dropdown_omvendt_toggle_subrutine   = () => { // console.log("> header.js > set_header_dropdown_click_omvendt__")

        // Header > Dropdown > Omvendt
        set_is_header_dropdown_omvendt_toggle();

        // Header > Dropdown > Par el > Collapse all
        set_header_doc_item_mat_open_reset();

        // Header > YY > Oppdater > Async
        set_header_yy_path_yp_async(delay_ref);
    };
    const set_header_doc_item_mat                   = useCallback( (arr) => {dispatch(setHeaderDocItemMat(arr))}, [dispatch]);
    const set_header_doc_item_mat_open              = (arr) => {dispatch(setHeaderDocItemMatOpen(arr))};
    const set_header_doc_item_mat_open_reset        = ()    => {dispatch(setHeaderDocItemMatOpenReset())};
    const set_header_yy                             = useCallback( (val) => {dispatch(setHeaderYY(val))}, [dispatch]);
    const set_header_yy_init_val                    = useCallback( (val) => {dispatch(setHeaderYYInitVal(val))}, [dispatch]);
    const header_mobile_styles                      = (header_dropdown_is_down_global, cls_par_ch) => {

        // Mobile
        if (cls_par_ch === "header_item_mobile_par_pointer_wrapper") {
            const _is_header_dropdown_is_down = () => {

                // Dropdown > Expanded
                if (header_dropdown_is_down_global === true)    {return "auto"};

                // Dropdown > Collapsed
                if (header_dropdown_is_down_global === false)   {return "0px"};
            };

            return {
                width:              "100%",
                height:             `${_is_header_dropdown_is_down()}`
            };
        };

        // Desktop
        if (cls_par_ch === "header_item_desktop_par_ch") {
            return {
                width:          "100%",
                // zIndex:         -10
            }
        };
    };

    // Path
    const set_path_is_hide                          = useCallback( ()    => {dispatch(setPathIsHide())}, [dispatch]);
    const set_path_yp                               = useCallback( (typ) => {dispatch(setPathYP(typ))}, [dispatch]);

    // Window > xx
    const _winow_xx_is_debug                        = false;
    const [window_width, setWindowWidth]            = useState(window.innerWidth);
    const set_window_width                          = () => {setWindowWidth(window.innerWidth)};
    const window_is_device_view                     = useCallback( () => { // console.log("xx > ", window_width)

        // Mobile 1
        if (window_width > lay_window_width_limit_xx_2 && window_width < lay_window_width_limit_xx_1)      {return lay_window_device_view_mobile_1}

        // Mobile 2
        else if (window_width <= lay_window_width_limit_xx_2)      {return lay_window_device_view_mobile_2}

        // Desktop
        else if (window_width >= lay_window_width_limit_xx_1)     {return lay_window_device_view_desktop}

        // No device
        else                                                    {return lay_window_device_view_no_device}

        // Dep
    }, [window_width]);
    const set_win_typ                               = useCallback( (val) => {dispatch(setWinTyp(val))}, [dispatch]);
    const page_core_reset_standard                  = useCallback( () => { // console.log("page_core_reset_standard!")

        // Set header
        set_header_dropdown_is_false_global();

        // Dep
    }, [set_header_dropdown_is_false_global]);
    const window_width_dyn                          = useCallback( () => {

        /* Søkeord > FIX_header_warning */

        // Win typ
        const _win_typ = window_is_device_view();
        setTimeout(() => {set_win_typ(_win_typ);}, delay_warning_qf);   // Ori (uten delay_warning_qf) > set_win_typ(_win_typ);

        // Mobile 1
        if (_win_typ   === lay_window_device_view_mobile_1)       {} // Bypass

        // Mobile 2
        else if (_win_typ   === lay_window_device_view_mobile_2)       {} // Bypass

        // Desktop
        else if (_win_typ   === lay_window_device_view_desktop)      {page_core_reset_standard();}

        // No device
        else if (_win_typ   === lay_window_device_view_no_device)    {} // Bypass

        // Dep
    }, [window_is_device_view,
        page_core_reset_standard,
        set_win_typ]);

    // Window > yy
    const [window_yy, setWindowYY]                  = useState(window.innerHeight);
    const set_window_width_yy                       = () => {setWindowYY(window.innerHeight)};

    // Header / Path
    const _set_header_yy_path_yp                    = useCallback( () => {

        /* - Note > Responsive > Oppdater yy og yp etter endring (f.eks. resize window osv.) */

        /* Init */
        let _header_yy = 0;

        /* Mobile 1 og 2 */
        if (window_is_device_view()    === lay_window_device_view_mobile_1 ||
                 window_is_device_view()    === lay_window_device_view_mobile_2
        ) {
            _header_yy
            = lay_is_ref(header_mobile_ref, lay_yy)
            + lay_is_ref(header_desktop_or_dropdown_ref, lay_yy);}

        /* Desktop */
        else if (window_is_device_view()    === lay_window_device_view_desktop) {
            _header_yy
            = lay_is_ref(header_desktop_or_dropdown_ref, lay_yy);}

        /* No device */
        else if (window_is_device_view()    === lay_window_device_view_no_device) {}; // Bypass (foreløpig ubrukt)

        /* Header > YY > Set fra ref */
        set_header_yy(_header_yy);

        /* Header > Setter header_yy fra ref første gangen*/
        /* - header_yy_init_val brukes i resetting av collapse header_dropdown */
        /* - State lagger hvis ikke */
        if (header_yy_init_val === 0) {
            set_header_yy_init_val(_header_yy);
        };

        /* Path > YP > Set fra ref til header_yy */
        set_path_yp(_header_yy)

        // Dep
    }, [window_is_device_view,
        set_header_yy,
        set_header_yy_init_val,
        set_path_yp,
        header_yy_init_val,
        header_mobile_ref,
        header_desktop_or_dropdown_ref]);
    const set_header_yy_path_yp_async               = useCallback( (delay) => {

        /* - Note */
        /*   - Async fordi ref tar tid å loade */
        /*   - click_doc_page_og_par er derimot ikke async (delay_arg_0) */

        const _set_header_yy_path_yp_async = () => {

            _set_header_yy_path_yp();

        };

        setTimeout(() => {_set_header_yy_path_yp_async()}, delay);
    }, [_set_header_yy_path_yp]);
    const _set_header_yy_path_yp_hardkodet          = useCallback( () => {

        // Hardkodet versjon for Initx
        const _header_yy = header_yy_hardkodet;
        set_header_yy_init_val(_header_yy);
        set_path_yp(_header_yy)

        // Dep
    }, [set_header_yy_init_val,
        set_path_yp]);

    // eslint-disable-next-line
    const scroll_yy_arr                             = [0, 0]; // Ubrukt > Direction > Merk: kan også være let-variabel
    const set_header_dropdown_mobile_hele_r         = useCallback( (val) => {dispatch(setHeaderDropdownMobileHeleR(val))}, [dispatch]);
    const {header_dropdown_mobile_hele_r}           = useSelector( (state) => {return state[redux_header]});

    // Scroll
    const handle_scroll                             = useCallback( () => {

        // Debug
        const _debug_lay        = false;
        const _debug_diff       = false;

        // Init
        const _offset_yp        = window.pageYOffset;
        const _drop_yy          = lay_is_ref(header_desktop_or_dropdown_ref, lay_yy);
        const _dropdown_yy
        = _drop_yy
        - _offset_yp
        + header_banner_yy;

        // Ubrukt > Scroll arr > Direction > Ubrukt, men kan bli praktisk
        scroll_yy_arr.push(_offset_yp);

        // Ubrukt > Diff > * (-1) -> Scroll nedover er positivt
        const _diff
        = (scroll_yy_arr[scroll_yy_arr.length - 2]
        - scroll_yy_arr[scroll_yy_arr.length - 1]) * (-1);
        if (_debug_diff === true) {
            console.log("diff :: ", _diff)
        };

        // Gap > Mellom dropdown og resten av siden (i bakgrunnen)
        const _gap
        = window_yy
        - _drop_yy
        - header_banner_yy
        + _offset_yp

        // Green > Default innenfor siden > Ingen scroll
        if (_dropdown_yy < window_yy) {
            if (header_dropdown_mobile_hele_r !== header_dropdown_mobile_hele_fixed) { // console.log("header_dropdown_mobile_hele > TRX :: ", header_dropdown_mobile_hele_r)
                set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_fixed);
            };
        };

        // Red > På grensen av max scollet / å gå over siden > Ingen scroll
        if (_dropdown_yy === window_yy) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_dynamisk_none);
        };

        // Blue > Over siden > Scroll
        if (_dropdown_yy > window_yy) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_dynamisk);
        };

        // Orange > Max scrollet > Utenfor siden > Ingen scroll
        if (_gap > header_gap_min && _gap < header_gap_max) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_fixed_limit);
        };

        // Debug > Lay
        if (_debug_lay === true) {
            console.log("***")
            console.log("win > yy :: ", window_yy)
            console.log("drp > yy :: ", _dropdown_yy)
            console.log("drp > yp :: ", _offset_yp);
        };

        // Dep
    }, [scroll_yy_arr,
        window_yy,
        header_dropdown_mobile_hele_r,
        set_header_dropdown_mobile_hele_r]);
    const dropdown_status                           = () => {

        // Window > DEV // Debug console.log("> Scroll > _Admin.js") // DEV_auto_scroll
        window.scrollTo({top: 0, behavior: scroll_smooth});

        // Async
        const funk_async = () => {
            const _funk_async = () => {

                // Scroll init
                handle_scroll();

                /// handleNavigation();

            };
            setTimeout(() => {_funk_async()}, 100);
        };
        funk_async();

    };
    const _get_top                                  = () => {

        const _top_yy
        = window_yy
        - lay_is_ref(header_desktop_or_dropdown_ref, lay_yy)
        - header_banner_yy; // Alternativ hvis gap under max mobile > _banner_yy + 16 eller 32

        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_fixed_limit)      {return _top_yy}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_fixed)            {return 0}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_dynamisk)         {return 0}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_dynamisk_none)    {return _top_yy}

    };

    // Page core
    const page_core_exit                            = () => {

        // Page core
        page_core_reset_standard();
    };

    // Doc
    const {doc_slug}                                = useSelector( (store) => {return store[redux_doc]});
    const set_do_navigate                           = (arr) => {dispatch(setDoNavigate(arr))};

    // Click
    const click_meny_burger                         = () => { // console.log("click meny!")

        // Toggle
        set_is_header_dropdown_omvendt_toggle_subrutine();

        // Dropdown status
        dropdown_status();

    };
    const click_logo                                = () => {

        // Page core > Exit
        page_core_exit();

        // Do_navigating
        set_do_navigate([
            0,
            route_root,
            json_root_pk]);

    };
    const click_profil_img                          = () => {

        // Page core > Exit
        page_core_exit();

        // Do_navigating
        set_do_navigate([
            doc_ingen_blokk,
            `${route_profil_disp_pk}${user.username}`,
            json_profil_disp_pk]);

    };
    const click_doc_page_og_par                     = (i, item_par) => { // console.log("> click_doc_page_og_par > item_par :: ", item_par)

        /* - Note */
        /*   - Switcher mellom om header-el er et par-el eller page */
        /*     - Par-el > Expand / collapse header dropdown */
        /*     - Page > Redirekt til siden */

        // Dropdown status
        dropdown_status();

        // Ingen page > Kun for expand / collapse par el
        if (!item_par[key_page]) { // console.log("Click par!")

            // Mobile > Kan expande flere par
            if (header_dropdown_is_down_global === true) {
                set_header_doc_item_mat_open([i, !header_doc_item_mat[i][key_open]]);
            };

            // Desktop > Kan kun expande 1 par
            if (header_dropdown_is_down_global === false) {
                set_header_doc_item_mat_open_reset();
                set_header_doc_item_mat_open([i, !header_doc_item_mat[i][key_open]]);
            };

            // Header > YY > Oppdater > Ingen delay / Ingen async
            set_header_yy_path_yp_async(delay_arg_0);

        };

        // Page
        if (item_par[key_page]) {

            // Note: Type kan være object -> String cast

            // Page core > Exit
            page_core_exit();

            // _page
            const _page = item_par[key_page] // console.log("header.js > page :: ", _page)

            /* Root */
            if (_page === String(json_page_landing)) {

                // Do_navigating
                set_do_navigate([
                    0,
                    route_root,
                    json_root_pk]);}

            /* Home (header shortcut) */
            else if (_page === String(json_page_home_header_shortcut)) { // console.log("Hit > Home (header shortcut)")

                // Do_navigating
                set_do_navigate([
                    0,
                    route_root,
                    json_root_pk]);}

            /* Profil disp */
            else if (_page === String(route_profil_disp_pk)) {

                // sokeord_white_screen_user_profil_list

                // Do_navigating
                set_do_navigate([
                    0,
                    `${route_profil_disp_pk}${user.username}`,
                    json_profil_disp_pk]);}


            /* Profil List */
            else if (_page === String(route_user_profil_list)) {



                // Do_navigating
                set_do_navigate([
                    0,
                    _page,
                    route_user_profil_list]);}


            /* Signup */
            else if (_page === String(route_sign_up)) {

                // Path > Hide
                set_path_is_hide();

                // Do_navigating
                set_do_navigate([
                    0,
                    _page,
                    json_root_pk]);}

            /* Signin */
            else if (_page === String(route_sign_in)) {

                // Path > Hide
                set_path_is_hide();

                // Do_navigating
                set_do_navigate([
                    0,
                    _page,
                    json_root_pk]);}

            /* Signout */
            else if (_page === String(json_page_sign_out,)) {

                // User signout
                signout_user();

                // Do_navigating
                set_do_navigate([
                    0,
                    route_root,
                    json_root_pk]);}

            /* Default > url_frontend_xyz > vanlig frontend-url */
            else {

                // - sokeord_white_screen_user_profil_list
                // - Var tidligere blokken til bl.a. route_user_profil_list
                //   som breaket koden
                // - Skjermen ble da helt hvit uten feilemelding
                // - Det kan mao. at denne blokken / set_do_navigate()-fn her,
                //   egentlig ikke fungere nå / har legacy-arg


                // Do_navigating
                set_do_navigate([
                    0,
                    _page,
                    _page]);


                }

        };

    };
    const click_doc_ch                              = (slug) => {

        // Debug > console.log("***") // console.log("slug_arg :: ", slug); // console.log("slug_doc :: ", doc_slug)

        // Klikker ny doc > Redirect
        if (slug !== doc_slug) {

            // Spinner > True > Har klikket ch og skal redirected videre
            set_is_spinner(true);

            // Do_navigating
            set_do_navigate([
                0,
                route_doc,
                slug]);

        };

        // Klikker samme doc samme user er på > Ingen endring
        if (slug === doc_slug) {};

        // Page core > Exit
        page_core_exit();



    };
    const click_signup                              = () => {

        // Path > Hide
        set_path_is_hide();

        // Page core > Exit
        page_core_exit();

        // Do_navigating
        set_do_navigate([
            0,
            route_sign_up,
            json_sign_up_pk]);

        // Async
        const funk_async = () => {
            const _funk_async = () => {
                // Do_navigating
                set_do_navigate([
                    0,
                    route_sign_up,
                    json_sign_up_pk]);
                    };
            setTimeout(() => {_funk_async()}, 100);
        };
        funk_async();

    };
    const click_custom                              = () => {

        // Page core > Exit
        page_core_exit();

        // Do_navigating
        set_do_navigate([
            0,
            route_root,
            json_root_pk]);

    };
    const click_developer                           = () => {

        // Page core > Exit
        page_core_exit();

        // Do_navigating
        set_do_navigate([
            0,
            route_doc,
            header_developer_url_frontend_json_slug]);
    };

    // Is
    const is_item_auth                              = (item_par) => {

        /* Root */
        if (item_par.page === String(json_page_landing))                    {if (header_landing_item_in_header === true) {return true} else {return false};}

        /* Admin */
        else if (item_par.page === String(route_admin))                     {if (user && is_superuser === true) {return true} else {return false};}

        /* Profil Disp */
        else if (item_par.page === String(route_profil_disp_pk))            {if (user) {return true} else {return false};}

        /* Signout > Logget > Inn (vises) / Ut (vises ikke) */
        else if (item_par.page === String(json_page_sign_out))              {if (user) {return true} else {return false};}

        /* Signin > Logget > Inn (vises ikke) / Ut (vises) */
        else if (item_par.page === String(route_sign_in))                   {if (user) {return false} else {return true};}

        /* Signup > Logget > Inn (vises ikke) / Ut (vises) */
        else if (item_par.page === String(route_sign_up))                   {if (user) {return false} else {return true};}

        /* Default */
        else                                                                {return true};

    };
    const is_item_settings                          = (item_par) => {

        /* Landing */
        if (item_par.page === String(json_page_landing))                {if (header_landing_item_in_header === true) {return true} else {return false};}

        /* Mal */
        else if (item_par.page === String(route_mal,))                  {if (header_mal_item_in_header === true) {return true} else {return false};}

        /* Footer */
        else if (item_par.page === String(route_footer,))               {if (header_footer_item_in_header === true) {return true} else {return false};}

        /* Default */
        else                                                            {return true};

    };
    const is_item_cls                               = () => {

        // Mobile > Ch el / Custom el / Developer el
        if (header_dropdown_is_down_global === true)    {return "header_item_mobile_ch_custom"}

        // Desktop > Logo / Custom el / Developer el
        if (header_dropdown_is_down_global === false)   {return "header_item_desktop_par_default"};

    };
    const is_par_arrow_cls                          = (item_par) => {

        // Mobile > Standard el / ikke par el > No arrow > Txt
        if (item_par[key_page] !== "") {return "txt_norm_beige_2_link_marg_L_header_par_mobile_no_arrow"}

        // Mobile > Par el > Arrow > Txt
        if (item_par[key_page] === "") {return "txt_norm_beige_2_link_marg_L_header_par_mobile"}
    };
    const is_item_doc_desktop_par_style_marg_extra  = (item_par) => {

        // Init
        let _style = {};

        // Desktop > Par el og tagg_src > Marg extra > Quickfix
        if (item_par.ch_arr.length > 0 &&
            item_par.ch_arr[0].tagg_src === 1) {

                // Style > Margin left
                _style = {
                    marginLeft:     `${header_desktop_par_extra_marg}rem`,
                    background:     "transparent" // Debug: "blue"
                };
        };

        // Return > Style
        return _style
    };

    // JSX
    const _mobile_header                            = () => {
        return (
            <div
                className   = "header_banner_mobile"
                ref         = {header_mobile_ref}>

                <div className="flex_col_TL">
                    <div className="flex_row_LT">

                        <div className="header_banner_mobile_left">
                            <div className="header_item_desktop_par_default">
                                <Icon
                                    icon_size={grp_icon_norm_24_24}
                                    icon_cls="icon_menu_burger_beige_2"
                                    on_click={() => click_meny_burger()}/>

                            </div>
                        </div>
                        <div className="header_banner_mobile_center">
                            <div className="header_item_desktop_par_default">
                                <Logo
                                    cls="logo_header_mobile"
                                    on_click={() => click_logo()}/>
                            </div>
                        </div>
                        <div className="header_banner_mobile_right">
                            <div className="header_item_desktop_par_default">
                                {user ? (
                                    <Img
                                        img_grp={grp_img_mikro}
                                        img_subgrp={grp_img_sub_bilde_profil}
                                        img_src={img_profil}
                                        on_click={() => click_profil_img()}/>
                                ) : (
                                    <Btn
                                        btn_typ={btn_typ_col}
                                        btn_grp={grp_btn_trans_white}
                                        txt={get_txt(signup_tittel, human_lang)}
                                        on_click={() => click_signup()}/>
                                )}
                            </div>
                        </div>

                        {/* Debug > Window xx */}
                        {_winow_xx_is_debug &&
                            <div className="tekst">
                                {window_width}
                            </div>}

                    </div>
                    {/* - Note: Mulig path plassering her */}
                </div>
            </div>
        )
    };
    const _item_doc                                 = (item_par, index, cls_par_ch, cls_par, cls_wrap) => {
        return (
            <>
            {is_item_auth(item_par) &&
            is_item_settings(item_par) &&
                <>

                {/* Item */}
                <div
                    className   = {cls_par_ch}
                    style       = {header_mobile_styles(header_dropdown_is_down_global, cls_par_ch)}
                    onClick     = {() => click_doc_page_og_par(index, item_par)}>

                    {/* Par > Collapsed / Expanded */}
                    <div
                        className   = {cls_wrap}>

                        {/* Mobile */}

                            {/* Mobile > Collapsed */}
                            {header_dropdown_is_down_global &&
                            !item_par[key_page] &&
                            !item_par[key_open] &&
                                <Icon
                                    icon_size={grp_icon_norm_48_12}
                                    icon_cls="icon_arrow_collapsed_beige_2"
                                    icon_wrapper="inline_icon_wrapper"
                                    on_click={() => click_doc_page_og_par(index, item_par)}/>}

                            {/* Mobile > Expanded */}
                            {header_dropdown_is_down_global &&
                            !item_par[key_page] &&
                            item_par[key_open] &&

                                <Icon
                                    icon_size       = {grp_icon_norm_48_20}
                                    icon_cls        = "icon_arrow_expanded_beige_2"
                                    icon_wrapper    = "inline_icon_wrapper"
                                    on_click        = {() => click_doc_page_og_par(index, item_par)}/>}

                        {/* Desktop */}

                            {/* Desktop > Collapsed */}
                            {!header_dropdown_is_down_global &&
                            !item_par[key_page] &&
                            !item_par[key_open] &&
                                <Icon
                                    icon_size={grp_icon_norm_48_12}
                                    icon_cls="icon_arrow_collapsed_beige_2"
                                    icon_wrapper="inline_icon_wrapper"
                                    on_click={() => click_doc_page_og_par(index, item_par)}/>}

                            {/* Desktop > Expanded */}
                            {!header_dropdown_is_down_global &&
                            !item_par[key_page] &&
                            item_par[key_open] &&
                                <Icon
                                    icon_size={grp_icon_norm_48_20}
                                    icon_cls="icon_arrow_expanded_beige_2"
                                    icon_wrapper="inline_icon_wrapper"
                                    on_click={() => click_doc_page_og_par(index, item_par)}/>}

                        {/* Mobile > Par > Tittel */}
                        {header_dropdown_is_down_global &&
                            <div className={is_par_arrow_cls(item_par)}>
                                {get_txt(item_par.tittel, human_lang)}
                            </div>}

                        {/* Desktop > Par > Tittel */}
                        {!header_dropdown_is_down_global &&
                            <div
                                className={cls_par}
                                style={is_item_doc_desktop_par_style_marg_extra(item_par)}>
                                {get_txt(item_par.tittel, human_lang)}
                            </div>}

                    </div>

                    {/* Ch */}

                        {/* Ch > Mobile > Icon / Tittel */}
                        {header_dropdown_is_down_global &&
                        item_par[key_open] &&

                            <>
                            {item_par[key_ch_arr].map(item_ch =>
                                <React.Fragment key={item_ch.tittel}>
                                    <div className="strek_header_ch"></div>
                                    <div
                                        className="txt_norm_beige_2_link_marg_LT_header_ch_mobile"
                                        onClick={() => click_doc_ch(item_ch.slug)}>
                                            <div className="flex_row_LC_G05">
                                                <Icon
                                                    icon_size="norm_tittel"
                                                    icon_cls={item_ch.icon}/>
                                                <div className={is_item_cls()}>
                                                    {get_txt(item_ch.tittel, human_lang)}
                                                </div>
                                            </div>
                                    </div>
                                </React.Fragment>
                            )}
                            </>}

                        {/* Ch > Desktop > (Inkludert dropdown) */}
                        {!header_dropdown_is_down_global &&
                        item_par[key_open] &&

                            <div
                                className   = "header_desktop_dropdown_ch"
                                // Debug > style       = {{zIndex: -10, backgroundColor: "green"}}
                                >
                                {item_par[key_ch_arr].map(item_sub =>
                                    <div
                                        key={item_sub.tittel}
                                        className="txt_norm_beige_2_link_marg_LT_header_desktop"
                                        // Debug > style={{zIndex: -10, backgroundColor: "red"}}
                                        onClick={() => click_doc_ch(item_sub.slug)}>
                                            {get_txt(item_sub.tittel, human_lang)}
                                    </div>
                                )}
                            </div>}

                </div>

                {/* Strek */}
                {header_dropdown_is_down_global &&
                    <div className="strek_header"></div>}
                </>}
            </>
        );
    };
    const _item_arr                                 = (header_cls, cls_par_ch, cls_par, cls_wrap) => {
        return (
            <div
                ref         = {header_desktop_or_dropdown_ref}
                className   = {header_cls}
                style       = {{
                    /// top:        `${dropdown_yp}px`,
                    top:        `${_get_top()}px`, // _get_top
                    zIndex:     "var(--z_index_header_dropdown_ch)"
                }}

                /*
                onWheel     = { event => {
                    if (event.nativeEvent.wheelDelta > 0) {
                      // console.log('scroll up');
                    } else {
                      // console.log('scroll down');
                    }
                  }}
                */
                >

                    {window_width >= lay_window_width_limit_xx_1 &&
                        <div className="header_item_desktop_par_default">

                            {/* Logo*/}
                            <Logo
                                cls      = "logo_header_desktop"
                                on_click = {() => click_logo()}/>

                            {/* Debug > Window xx */}
                            {_winow_xx_is_debug &&
                                <div className="tekst">
                                    {window_width}
                                </div>}

                        </div>}

                    {_item_custom(
                        `${get_txt(landing_tittel, human_lang)}`,
                        route_root,
                        false)}

                    {header_doc_item_mat.map((item_par, index) =>
                        <React.Fragment key={index}>
                            {_item_doc(
                                item_par,
                                index,
                                cls_par_ch,
                                cls_par,
                                cls_wrap)}
                        </React.Fragment>)}

                    {_item_developer(
                        get_txt(header_developer_tittel, human_lang),
                        header_developer_url_frontend,
                        header_developer_item_in_header)}

            </div>
        )
    };
    const _item_custom                              = (tit, link, show) => {
        return (
            <>
                {show &&
                    <>
                    <Link
                        className={is_item_cls()}
                        to={link}
                        onClick={() => click_custom()}>
                            <div className="txt_norm_beige_2_link_header">
                                {tit} *** Custom ***
                            </div>
                    </Link>
                    <div className="strek_header"></div>
                    </>}
            </>
        );
    };
    const _item_developer                           = (tit, link, show) => {
        return (
            <>
                {user &&
                is_superuser === true &&
                show &&

                    <>
                    <Link
                        className={is_item_cls()}
                        to={link}
                        onClick={() => click_developer()}>
                        <div className="txt_norm_beige_2_link_header">
                            {tit}
                        </div>
                    </Link>
                    <div className="strek_header"></div>
                    </>}
            </>
        )
    };
    const _master_responsive                        = () => {
        return (
            <div>

            <DoNavigate/>
            <DoHeaderDropdownFalse/>

            {header_is_visible &&
                <>

                    {/* Dyn bredde > Søkeord > FIX_header_warning */}
                    {window_width_dyn()}

                    {/* 01_header.css > @media > show / hide */}
                    {_mobile_header()}

                    {header_dropdown_is_down_global ? (
                        _item_arr(
                            /// header_dropdown_mobile_hele,
                            header_dropdown_mobile_hele_r,
                            "header_item_mobile_par_pointer_wrapper",
                            "txt_norm_beige_2_link_marg_L_header_par_mobile",
                            "header_item_mobile_par_wrapper"
                            )
                    ) : (
                        _item_arr(
                            "header_banner_desktop", // 01_header.css > @media > show / hide
                            "header_item_desktop_par_ch",
                            "txt_norm_beige_2_link_marg_header_desktop",
                            "header_item_desktop_par_wrapper")
                    )}

                </>}

            </div>
        )
    };

    // Fetch
    const fetch_get_doc_header_arr                  = useCallback( () => {

        // Clean url
        const _clean_url = url_clean(url_api_doc_header);

        // Initx > Executes 1 gang
        fetch(_clean_url, {
            method:     http_method_get,
            headers:    http_headers_content_type_application_json
        })
        .then(res => res.json())
        .then(dat => { // console.log("fetch_get_doc_header_arr > dat :: ", dat)

            // Debug
            if (debug_header_doc_header_arr) {
                console.log(tekst_debug_statement("Initx", "F000", "header.js", "fetch_get_doc_header_arr()", ""));
            };

            // Debug > Filtrer ut spill > Søkeord > DEV_filtrer_under_utvikling
            let dat_tmp = [];

            // Iterate doc item mat
            for (let k = 0; k < dat.length; k++) { // console.log("dat[k].slug :: ", dat[k].slug);

                // DEV > Filtrer ut spill
                if (dat[k].slug !== "spill") {

                    dat_tmp.push(dat[k]);

                };

            };

            // Header > Doc item
            set_header_doc_item_mat(dat_tmp); // ORI > dat

            // Load > Done > Warnin issue
            set_is_load_done_load_async();

        })

        // Dep
    }, [set_header_doc_item_mat,
        set_is_load_done_load_async]);

    // useEffect
    useEffect( () => {

        // console.log("useFX!")

        // Window > Add event listener > Resize
        window.addEventListener(lay_window_resize, set_window_width);

        // Window > Add event listener > Resize
        window.addEventListener("resize_yy", set_window_width_yy);

        // WIN 2 > Window scroll > Forklaring > DEV
        window.addEventListener(scroll_item, handle_scroll, { passive: true });

        // Fetch > Doc > Header arr > Issue > Warning: Cannot update a component (`LandingPage`) while rendering a different component (`Header`).
        fetch_get_doc_header_arr();
        // Ikke async-issue > setTimeout(() => {fetch_get_doc_header_arr();}, 5000);

        // Header > YY > Oppdater > Hardkodet for Initx
        if (header_is_yy_hardkodet === true)    {_set_header_yy_path_yp_hardkodet();}
        if (header_is_yy_hardkodet === false)   {set_header_yy_path_yp_async(delay_initx_header_ref);}

        // WIN 2 > Return
        return () => {

            /// window.removeEventListener(scroll_item, handle_scroll);

        };

        // Dep

        // eslint-disable-next-line
    }, [
        _set_header_yy_path_yp_hardkodet,
        fetch_get_doc_header_arr,
        set_header_yy_path_yp_async,
        // handle_scroll // Mute hvis skroll ok

    ]);

    // Return
    return (<>{is_load_done && _master_responsive()}</>)

};


export default Header;
