/* ****************************************** */
/* Lay */
/* ****************************************** */

// Unit
const lay_unit_px_per_rem                   = 16.0;     // px

// XY
const lay_xx                                = "xx";
const lay_xp                                = "xp";
const lay_yy                                = "yy";
const lay_yp                                = "yp";

// Window > Limits > Søkeord CSS_Media
const lay_window_width_limit_xx_1           = 701.0;    // px > Søkeord
const lay_window_width_limit_xx_2           = 530.0;    // px > Søkeord

// Window > Vari
const lay_window_resize                     = "resize";
const lay_window_device_view_mobile_1       = "mobile_1";
const lay_window_device_view_mobile_2       = "mobile_2";
const lay_window_device_view_desktop        = "desktop";
const lay_window_device_view_no_device      = "no_device";

// Path
const lay_path_yy_init                      = lay_unit_px_per_rem * 2.0 + 1.0 * 2.0;        // px, 2 rem (standard yy) + 1px border * 2 (oppe og nede)
const lay_path_yp_init                      = lay_unit_px_per_rem * 4.0;                    // rem

export {

    // Unit
    lay_unit_px_per_rem,

    // XY
    lay_xx,
    lay_xp,
    lay_yy,
    lay_yp,

    // Window > Limits > Søkeord CSS_Media
    lay_window_width_limit_xx_1,
    lay_window_width_limit_xx_2,

    // Window > Vari
    lay_window_resize,
    lay_window_device_view_mobile_1,
    lay_window_device_view_mobile_2,
    lay_window_device_view_desktop,
    lay_window_device_view_no_device,

    // Path
    lay_path_yy_init,
    lay_path_yp_init,

};