import {
    redux_doc
} from "../../konstanter/redux";
import {
    json_root_pk
} from "../../konstanter/url";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    doc_slug:               json_root_pk,
    doc_current:            {},
    url_frontend_route:     "",
    doc_index:              0,
    doc_arr:                [],
    doc_fetch:              true,

    blokk_selected_slug:    "",
    blokk_selected_index:   0,
    blokk_link:             "",
    blokk_yy_mat:           []
};

const docSlice = createSlice({
    name:                   redux_doc,
    initialState:           initialState,
    reducers: {

        // Doc current
        setDocCurrent(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_current = action.payload;

        },
        setDoNavigate(state, action) {

            /* Note
            - Auto-directer når set_do_navigate får param nedenfor
            - Standard-param
                - Param
                    // Do_navigating
                    set_do_navigate([
                        doc_ingen_blokk,
                        route_doc,
                        slug]);
                - Denne vil route til doc og vise riktig path definert i ygg.
            - Custom, eks:
                    // Do_navigating
                    set_do_navigate([
                        doc_ingen_blokk,
                        "spill",
                        ""]);
            */

            // Init
            const _blokk        = action.payload[0];    // Blokk som scrolles til hvis link klikkes (altså, ikke med "doc_ingen_blokk")
            const _url          = action.payload[1];    // Url definert i App.js
            const _doc_slug     = action.payload[2];    // Doc definert i data_ygg.py > Setter path

            // Set vari
            state.blokk_selected_index      = _blokk;
            state.url_frontend_route        = _url;
            state.doc_slug                  = _doc_slug;

        },
        setDocIndex(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_index = action.payload;

        },
        setDocArr(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_arr = action.payload;

        },
        setDocFetch(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_fetch = action.payload;

        },
        setDocAndFetch(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_slug      = action.payload;
            state.doc_fetch     = true;

        },

        // Blokk
        setBlokkSelectedSlug(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_selected_slug = action.payload;

        },
        setBlokkSelected(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_selected_index = action.payload;

        },
        setBlokkLink(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_link = action.payload;

        },
        setBlokkLinkReset(state) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_link = "";

        },
        setBlokkYYMatReset(state) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_yy_mat = [];

        },
        setBlokkYYMatPush(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_yy_mat.push(action.payload);

        }
    }
});


export const {

    // Doc
    setDocCurrent,
    setDoNavigate,
    setDocIndex,
    setDocArr,
    setDocFetch,
    setDocAndFetch,

    // Blokk
    setBlokkSelectedSlug,
    setBlokkSelected,
    setBlokkLink,
    setBlokkLinkReset,
    setBlokkYYMatReset,
    setBlokkYYMatPush

} = docSlice.actions;


export default docSlice.reducer;
