import {
    createSlice
}                                                   from "@reduxjs/toolkit";

import {
    key_open
}                                                   from "../../konstanter/key"
import {
    redux_header
}                                                   from "../../konstanter/redux"


const initialState                                  = {
    header_is_visible:                  true,
    header_dropdown_is_down_global:     false,
    header_doc_item_mat:                [],
    header_yy:                          0,
    header_yy_init_val:                 0,
    header_xx:                          0,
    win_typ:                            "",

    header_dropdown_mobile_hele_r:      "header_dropdown_mobile_hele_fixed"

};


const headerSlice                                   = createSlice({
    name:                               redux_header,
    initialState:                       initialState,
    reducers: {

        // Visible
        setIsHeaderVisible(state, action) {

            // Is visible
            state.header_is_visible = action.payload;

        },

        // Dropdown
        setIsHeaderDropdownOmvendtToggle(state) {

            // Dropdown > Toggle > Expanded / Collapsed
            state.header_dropdown_is_down_global = !state.header_dropdown_is_down_global;

        },
        setHeaderDropdownIsFalseGlobal(state) {

            // Dropdown > Set false > Collapsed
            state.header_dropdown_is_down_global = false;

        },

        // Header core
        setHeaderPageCoreStandardReset(state) {

            // setHeaderDocItemMatOpenReset > Iterate doc item mat
            for (let k = 0; k < state.header_doc_item_mat.length; k++) {

                // Item mat > Reset > Set all > false
                state.header_doc_item_mat[k][key_open] = false;

            };

            // setHeaderDropdownIsFalseGlobal > Dropdown > Set false > Collapsed
            state.header_dropdown_is_down_global = false;

        },

        // Item mat
        setHeaderDocItemMat(state, action) {

            // Item mat > Set fra API
            state.header_doc_item_mat = action.payload;

        },
        setHeaderDocItemMatOpen(state, action) {

            // Set open > True / False
            state.header_doc_item_mat[action.payload[0]][key_open] = action.payload[1];

        },
        setHeaderDocItemMatOpenReset(state) {

            // Iterate doc item mat
            for (let k = 0; k < state.header_doc_item_mat.length; k++) {

                // Item mat > Reset > Set all > false
                state.header_doc_item_mat[k][key_open] = false;

            };

        },

        // Header YY
        setHeaderYY(state, action) {

            // Header yy
            state.header_yy = action.payload;

        },
        setHeaderYYInitVal(state, action) {

            // Header yy init val
            state.header_yy_init_val = action.payload;

        },
        setHeaderYYReset(state) {

            // YY > Resetter til YY init-verdi
            state.header_yy = state.header_yy_init_val;

        },

        // Win typ
        setWinTyp(state, action) {

            // Set win typ
            state.win_typ = action.payload

        },

        // CSS Clas > redux
        setHeaderDropdownMobileHeleR(state, action) {

            // Init
            const _css = action.payload;

            // Set
            state.header_dropdown_mobile_hele_r = _css;

        }



    }
});


export const {

    // Visible
    setIsHeaderVisible,

    // Dropdown
    setIsHeaderDropdownOmvendtToggle,
    setHeaderDropdownIsFalseGlobal,

    // Page core
    setHeaderPageCoreStandardReset,

    // Item mat
    setHeaderDocItemMat,
    setHeaderDocItemMatOpen,
    setHeaderDocItemMatOpenReset,

    // Header YY
    setHeaderYY,
    setHeaderYYInitVal,
    setHeaderYYReset,

    // Win typ
    setWinTyp,

    // CSS class
    setHeaderDropdownMobileHeleR

}                                                   = headerSlice.actions;


export default headerSlice.reducer;