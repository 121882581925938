import React                                        from "react";
import ReactDOM                                     from "react-dom";

import {
    is_strict_mode
}                                                   from "../src/innstillinger/innstillinger";


import App                                          from "./App";


// Strict mode bypassed > 0 > Development
if (is_strict_mode === true) {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

// Strict mode bypassed
if (is_strict_mode === false) {
    ReactDOM.render(
            <App/>,
        document.getElementById("root")
    );
};
