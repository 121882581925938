import {
    _human_lang_default,
    nettside,
    selskap_as
}                                                   from "../innstillinger/innstillinger";

import {
    txt_human_lang_eng,
    txt_human_lang_no,
}                                                   from "../konstanter/components";
import {
    json_tittel_root,
    json_tittel_home,
}                                                   from "../konstanter/url";


/* ****************************************** */
/* Human lang */
/* ****************************************** */

// Shorthand
const _eng          = txt_human_lang_eng;
const _no           = txt_human_lang_no;
const _default      = _human_lang_default;


// Default
const _is_d                                 = (human_lang) => {
    if (human_lang === _default)  {return true};
    if (human_lang !== _default)  {return false};
};


// Txt
const _is_txt                               = (txt, txt_typ, human_lang, human_lang_typ) => {
    if (
        (txt === txt_typ && human_lang === human_lang_typ) ||
        (txt === txt_typ && _is_d(human_lang_typ))) {
            return true
    };
};


/* ****************************************** */
/* Debug */
/* ****************************************** */

// Versjon
const versjon                               = "versjon";
const txt_test                              = "txt_test";


/* ****************************************** */
/* Components */
/* ****************************************** */

// Header
const header_developer_tittel               = "_header_developer_tittel";

// Alert
const email_confirm_alert                       = "_email_confirm_alert";
const signin_glemt_pass_alert                   = "_signin_glemt_pass_alert";
const signin_glemt_pass_confirm_info_1_alert    = "_signin_glemt_pass_confirm_info_1_alert";
const signin_glemt_pass_confirm_info_2_alert    = "_signin_glemt_pass_confirm_info_2_alert";
const signin_wrong_password_alert               = "_signin_wrong_password_alert";
const username_updated_signin_again_alert       = "_username_updated_signin_again_alert";

// Btn
const upload_bilde_btn                      = "_upload_bilde_btn";
const update_btn                            = "_update_btn";                       // "Edit"
const update_profil_btn                     = "_update_profil_btn";
const update_profil_bilde_btn               = "_update_profil_bilde_btn";
const delete_btn                            = "_delete_btn";
const complete_btn                          = "_complete_btn";
const cancel_btn                            = "_cancel_btn";
const load_btn                              = "_load_btn";
const dump_btn                              = "_dump_btn";
const cycle_btn                             = "_cycle_btn";
const cleanup_bu_btn                        = "_cleanup_bu_btn";
const remove_btn                            = "_remove_btn";
const add_btn                               = "_add_btn";
const push_btn                              = "_push_btn";
const mal_many_btn                          = "_mal_many_btn";

// Cropper
const crop_bilde                            = "_crop_bilde";

// Input > Textarea
const bokstaver_igjen                       = "_bokstaver_igjen";
const bokstav_igjen                         = "_bokstav_igjen";


/* ****************************************** */
/* Gen */
/* ****************************************** */

// Human lang
const no_tittel                             = "_no_tittel";
const eng_tittel                            = "_eng_tittel";

// Msg > Error > Status code
const err_msg_auth_context_signin_user_400_error            = "_err_msg_auth_context_signin_user_400_error";
const err_msg_auth_context_signin_user_401_error            = "_err_msg_auth_context_signin_user_401_error";
const err_msg_auth_context_signin_user_404_error            = "_err_msg_auth_context_signin_user_404_error";
const err_msg_auth_context_signin_user_405_error            = "_err_msg_auth_context_signin_user_405_error";
const err_msg_auth_context_signin_user_ukjent_status_kode   = "_err_msg_auth_context_signin_user_ukjent_status_kode";
const err_msg_auth_update_token_400_error                   = "_err_msg_auth_update_token_400_error";
const err_msg_auth_update_token_401_error                   = "_err_msg_auth_update_token_401_error";
const err_msg_auth_update_token_404_error                   = "_err_msg_auth_update_token_404_error";
const err_msg_auth_update_token_ukjent_status_kode          = "_err_msg_auth_update_token_ukjent_status_kode";

// Msg > Custom
const err_msg_user_profil_redux_user_profil_feature_error   = "_err_msg_user_profil_redux_user_profil_feature_error";
const err_msg_redux_user_profil_feature_slice               = "_err_msg_redux_user_profil_feature_slice";


/* ****************************************** */
/* Context */
/* ****************************************** */

const auth_msg_user_eksisterer_ikke_enda      = "_auth_msg_user_eksisterer_ikke_enda";


/* ****************************************** */
/* Pages */
/* ****************************************** */


/* ****************************************** */
/* Landing */
/* ****************************************** */

// Root / Landing
const root_tittel                           = "_root_tittel";
const landing_tittel                        = "_landing_tittel";
const landing_tittel_sub                    = "landing_tittel_sub";
const landing_special_case_home_tittel      = "_landing_special_case_home_tittel";

// Card
const landing_tekst_mellom                      = [
    {
        tittel:     "DUMMY Kort 1",
        content:    "DUMMY Info 1",

        tittel_2:   "TXT DUMMY",
        icon:       "***",
        pos:        "***",
        txt:        "***",
        link:       "***"
    },
    {
        tittel:     "Kort 1",
        content:    "Info 1",

        tittel_1:   "Header 1", // Python
        icon:       "icon_python",
        pos:        "left",
        txt:        "Opplæring og programmer",
        link:       "python"
    },
    {
        tittel:     "Kort 2",
        content:    "Info 2",

        tittel_1:   "Header 2", // "JavaScript",
        icon:       "icon_javascript",
        pos:        "right",
        // txt:        "Opplæring og programmer",
        link:       "/user_profil_list"// "javascript"
    },
    {
        tittel:     "Kort 3",
        content:    "Info 3",

        tittel_1:   "Header 3", // "HTML",
        icon:       "icon_html",
        pos:        "left",
        txt:        "Opplæring og programmer",
        link:       "html"
    },
    {
        tittel:     "Kort 4",
        content:    "Info 4",

        tittel_1:   "Header 4", // "CSS",
        icon:       "icon_css",
        pos:        "right",
        txt:        "Opplæring og programmer",
        link:       "css"
    },

]

/* ****************************************** */
/* Mal */
/* ****************************************** */

const mal_tittel_hoved                      = "_mal_tittel_hoved";
const mal_tittel_undertittel                = "_mal_tittel_undertittel";
const mal_hello_val                         = "_mal_hello_val";


/* ****************************************** */
/* Admin */
/* ****************************************** */

// Admin > Ord
const push_el_new_file                      = "_push_el_new_file";
const add_dir                               = "_add_dir";
const remove_dir                            = "_remove_dir";

// Admin > I
const admin_tittel                          = "_admin_tittel";
const admin_db_tittel                       = "_admin_db_tittel";
const admin_user_profil_disp_data           = "_admin_user_profil_disp_data";
const admin_json_backend_cap                = "_admin_json_backend_cap";

// Admin > II
const admin_user_arr_tittel_cap             = "_admin_user_arr_tittel_cap";
const admin_user_profil_disp_tittel_cap     = "_admin_user_profil_disp_tittel_cap";
const admin_email_developer_cap             = "_admin_email_developer_cap";
const admin_test_navigation_cap             = "_admin_test_navigation_cap";
const admin_test_navigation_btn             = "_admin_test_navigation_btn";


/* ****************************************** */
/* Reg */
/* ****************************************** */

// Reg > Sign > I
const signup_tittel                         = "_signup_tittel ";
const signup_allerede_reg                   = "_signup_allerede_reg";
const signin_tittel                         = "_signin_tittel";                     // Alternativ: "Epost sendt";
const signin_ny_prog                        = "_signin_ny_prog";

// Reg > Sign > II
const signin_glemt_pass                     = "_signin_glemt_pass";
const signin_glemt_pass_tittel              = "_signin_glemt_pass_tittel";
const signin_glemt_pass_confirm             = "_signin_glemt_pass_confirm";         // Alternativ: "Reset-epost sendt"
const signin_glemt_pass_confirm_btn         = "_signin_glemt_pass_confirm_btn";     // Velg ord: resett / tilbakestill
const signin_glemt_pass_sett_nytt           = "_signin_glemt_pass_sett_nytt";
const signout_tittel                        = "_signout_tittel";
const reg_ugyldig                           = "_reg_ugyldig";

// Reg > Username
const username_tittel                       = "_username_tittel";
const username_sub_ikke_gyldig              = "_username_sub_ikke_gyldig_txt";      // "Minst 6 tegn, ingen spesialtegn"
const username_sub_ikke_gyldig_opptatt      = "_username_sub_ugyldig_opptatt";
const username_sub_gyldig                   = "_username_sub_gyldig";
const username_sub_gyldig_max               = "_username_sub_gyldig_max";

// Reg > Email
const email_tittel                          = "_email_tittel";
const email_sub_ugyldig                     = "_email_sub_ugyldig";
const email_sub_gyldig                      = "_email_sub_gyldig";                  // const email_txt_sub_gyldig_max = "Email er gyldig (max lengde)" // "Gyldig";
const email_sub_gyldig_max                  = "_email_sub_gyldig_max";
const reg_email_send_user_confirm_developer_txt     = "_reg_email_send_user_confirm_developer_txt";
const reg_email_send_glemt_passord_developer_txt    = "_reg_email_send_glemt_passord_developer_txt";

// Reg > Password > I
const password_tittel                       = "_password_tittel";
const password_sub_gyldig                   = "_password_sub_gyldig";
const password_sub_ikke_gyldig              = "_password_sub_ikke_gyldig";          // "Minst 6 tegn"
const password_svakt                        = "_password_svakt";

// Reg > Password > II
const password_middels                      = "_password_middels";
const password_sterkt                       = "_password_sterkt";
const password_max_lengde                   = "_password_max_lengde";
const password_for_langt                    = "_password_for_langt";


/* ****************************************** */
/* Profil */
/* ****************************************** */

// User profil > Disp > I
const user_profil_tittel                    = "_user_profil_tittel";
const user_profil_img_profil_tittel_txt     = "_user_profil_img_profil_tittel_txt";
const user_profil_navn                      = "_user_profil_navn";
const user_profil_navn_placeholder          = "_user_profil_navn_placeholder";
const user_profil_public_email              = "_user_profil_public_email";
const user_profil_public_email_placeholder  = "_user_profil_public_email_placeholder";

// User profil > Disp > II
const user_profil_bio                       = "_user_profil_bio";
const user_profil_bio_placeholder           = "_user_profil_bio_placeholder";
const user_profil_url_public                = "_user_profil_url_public";
const user_profil_url_public_placeholder    = "_user_profil_url_public_placeholder";
const user_profil_company                   = "_user_profil_company";
const user_profil_company_placeholder       = "_user_profil_company_placeholder";

// User profil > Account > Set > I
const user_profil_account_tittel                    = "_user_profil_account_tittel ";
const user_profil_account_konto_gruppe              = "_user_profil_account_konto_gruppe";
const user_profil_account_item_public_profil_tittel = "_user_profil_account_item_public_profil_tittel";
const user_profil_account_item_account_tittel       = "_user_profil_account_item_account_tittel";
const user_profil_account_item_lang_tittel          = "_user_profil_account_item_lang_tittel";

// User profil > Account > Set > II
const user_profil_account_item_account_passord_endret_alert_success_confirm     = "_user_profil_account_item_account_passord_endret_alert_success_confirm";
const user_profil_account_item_account_passord_endret_alert_fail_confirm        = "_user_profil_account_item_account_passord_endret_alert_fail_confirm";
const user_profil_account_item_notifications_tittel = "_user_profil_account_item_notifications_tittel";
const user_profil_account_item_email_tittel         = "_user_profil_account_item_email_tittel";

// User profil > Account > Set > Konto > Username
const usernavn_nytt_tittel                          = "_usernavn_nytt_tittel";
const usernavn_nytt_btn_endre                       = "_usernavn_nytt_btn_endre";
const usernavn_nytt_btn_update                      = "_usernavn_nytt_btn_update";

// User profil > Account > Set > Konto > Password
const password_nytt_tittel                          = "_password_nytt_tittel";
const password_nytt_btn_endre                       = "_password_nytt_btn_endre";
const password_nytt_btn_update                      = "_password_nytt_btn_update";
const password_dum                                  = "_password_dum";
const password_bekreft_tittel                       = "_password_bekreft_tittel";


/* ****************************************** */
/* Footer */
/* ****************************************** */

const footer_copyright                      = "_footer_copyright";
const footer_about_tittel                   = "_footer_about_tittel";
const footer_terms_tittel                   = "_footer_terms_tittel";
const footer_kurs_tittel                    = "_footer_kurs_tittel";
const footer_about_tittel_sub               = "_footer_about_tittel_sub";

/* ****************************************** */
/* foot.js > Nederst på hver side */
/* ****************************************** */

// ...


/* ****************************************** */
/* Get txt */
/* ****************************************** */

const get_txt = (txt, lang) => {

    /* ****************************************** */
    /* Debug */
    /* ****************************************** */

    // Test
    if (_is_txt(txt, txt_test,   lang, _eng))               {return "A test"};
    if (_is_txt(txt, txt_test,   lang, _no))                {return "En test"};


    // Versjon
    if (_is_txt(txt, versjon,   lang, _eng))                {return "Ver"};
    if (_is_txt(txt, versjon,   lang, _no))                 {return "Ver"};


    /* ****************************************** */
    /* Components */
    /* ****************************************** */

    // Header
    if (txt === header_developer_tittel)        {return "*** Python ***"};


    // Alert
    if (_is_txt(txt, email_confirm_alert,       lang, _eng))    {return "Confirm the user in the mail you´ve received."};
    if (_is_txt(txt, email_confirm_alert,       lang, _no))     {return "Bekreft brukeren i eposten som vi har sendt."};

    if (_is_txt(txt, signin_glemt_pass_alert,   lang, _eng))    {return "We´ll send you the reset link to your mail"};
    if (_is_txt(txt, signin_glemt_pass_alert,   lang, _no))     {return "Vi sender reset-linken til eposten din"};

    if (_is_txt(txt, signin_glemt_pass_confirm_info_1_alert,    lang, _eng))   {return "1. Open the email in a new tab."};
    if (_is_txt(txt, signin_glemt_pass_confirm_info_1_alert,    lang, _no))    {return "1. Åpne eposten i en ny fane."};

    if (_is_txt(txt, signin_glemt_pass_confirm_info_2_alert,    lang, _eng))   {return "2. Type in your new password here."};
    if (_is_txt(txt, signin_glemt_pass_confirm_info_2_alert,    lang, _no))    {return "2. Skriv inn det nye passordet her."};

    if (_is_txt(txt, signin_wrong_password_alert,               lang, _eng))   {return "Wrong username or password."};
    if (_is_txt(txt, signin_wrong_password_alert,               lang, _no))    {return "Feil brukernavn eller passord."};

    if (_is_txt(txt, username_updated_signin_again_alert,       lang, _eng))   {return "Sign in with your new username."};
    if (_is_txt(txt, username_updated_signin_again_alert,       lang, _no))    {return "Logg inn med det nye brukernavnet."};


    // Btn
    if (_is_txt(txt, upload_bilde_btn,          lang, _eng))   {return "Upload new profilbilde"};
    if (_is_txt(txt, upload_bilde_btn,          lang, _no))    {return "Last opp nytt profilbilde"};

    if (_is_txt(txt, update_btn,                lang, _eng))   {return "Update"};
    if (_is_txt(txt, update_btn,                lang, _no))    {return "Oppdater"};

    if (_is_txt(txt, update_profil_btn,         lang, _eng))   {return "Update profile"};
    if (_is_txt(txt, update_profil_btn,         lang, _no))    {return "Oppdater profil"};

    if (_is_txt(txt, update_profil_bilde_btn,   lang, _eng))   {return "Update profile image"};
    if (_is_txt(txt, update_profil_bilde_btn,   lang, _no))    {return "Oppdater bilde"};

    if (_is_txt(txt, delete_btn,                lang, _eng))   {return "Delete"};
    if (_is_txt(txt, delete_btn,                lang, _no))    {return "Slett"};

    if (_is_txt(txt, complete_btn,              lang, _eng))   {return "Complete the change"};
    if (_is_txt(txt, complete_btn,              lang, _no))    {return "Fullfør endringen"};

    if (_is_txt(txt, cancel_btn,                lang, _eng))   {return "Cancel"};
    if (_is_txt(txt, cancel_btn,                lang, _no))    {return "Avbryt"};

    if (_is_txt(txt, load_btn,                  lang, _eng))   {return "Load"};
    if (_is_txt(txt, load_btn,                  lang, _no))    {return "Load"};

    if (_is_txt(txt, dump_btn,                  lang, _eng))   {return "Dump"};
    if (_is_txt(txt, dump_btn,                  lang, _no))    {return "Dump"};

    if (_is_txt(txt, cleanup_bu_btn,            lang, _eng))   {return "Cleanup"};
    if (_is_txt(txt, cleanup_bu_btn,            lang, _no))    {return "Cleanup"};

    if (_is_txt(txt, cycle_btn,                 lang, _eng))   {return "Cycle"};
    if (_is_txt(txt, cycle_btn,                 lang, _no))    {return "Cycle"};

    if (_is_txt(txt, remove_btn,                lang, _eng))   {return "Remove"};
    if (_is_txt(txt, remove_btn,                lang, _no))    {return "Remove"};

    if (_is_txt(txt, add_btn,                   lang, _eng))   {return "Add"};
    if (_is_txt(txt, add_btn,                   lang, _no))    {return "Add"};

    if (_is_txt(txt, push_btn,                  lang, _eng))   {return "Push"};
    if (_is_txt(txt, push_btn,                  lang, _no))    {return "Push"};

    if (_is_txt(txt, mal_many_btn,              lang, _eng))   {return "Mal get many!"};
    if (_is_txt(txt, mal_many_btn,              lang, _no))    {return "Mal get many!"};


    // Cropper
    if (_is_txt(txt, crop_bilde,                lang, _eng))   {return "Crop your image"};
    if (_is_txt(txt, crop_bilde,                lang, _no))    {return "Beskjær bildet ditt"};

    // Input > Textarea
    if (_is_txt(txt, bokstaver_igjen,   lang, _eng))    {return "letters left"};
    if (_is_txt(txt, bokstaver_igjen,   lang, _no))     {return "bokstaver igjen"};

    if (_is_txt(txt, bokstav_igjen,     lang, _eng))    {return "letter left"};
    if (_is_txt(txt, bokstav_igjen,     lang, _no))     {return "bokstav igjen"};


    /* ****************************************** */
    /* Gen */
    /* ****************************************** */

    // Human lang
    if (_is_txt(txt, eng_tittel,                lang, _eng))   {return "English"};
    if (_is_txt(txt, eng_tittel,                lang, _no))    {return "Engelsk"};

    if (_is_txt(txt, no_tittel,                 lang, _eng))    {return "Norwegian"};
    if (_is_txt(txt, no_tittel,                 lang, _no))     {return "Norsk"};

    // Msg > Error > Status code
    if (_is_txt(txt, err_msg_auth_context_signin_user_400_error,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Wrowng username / password? :: "};
    if (_is_txt(txt, err_msg_auth_context_signin_user_400_error,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Feil brukernavn / passord? :: "};

    if (_is_txt(txt, err_msg_auth_context_signin_user_401_error,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Wrowng username / password? 2. is_active = False? :: "};
    if (_is_txt(txt, err_msg_auth_context_signin_user_401_error,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Feil brukernavn / passord? 2. is_active = False? :: "};

    if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Web site doesn´t exist"};
    if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Nettsiden finnes ikke"};

    if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Web site doesn´t exist"};
    if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Nettsiden finnes ikke"};

    if (_is_txt(txt, err_msg_auth_context_signin_user_405_error,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 405 > Failed to load resource: the server responded with a status of 405 (Method Not Allowed) > Wrong method POST / GET / PUT / DELETE in views.py / api.py"};
    if (_is_txt(txt, err_msg_auth_context_signin_user_405_error,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 405 > Failed to load resource: the server responded with a status of 405 (Method Not Allowed) > Feil method POST / GET / PUT / DELETE i views.py / api.py"};

    if (_is_txt(txt, err_msg_auth_context_signin_user_ukjent_status_kode,         lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > ukjent status kode :: "};
    if (_is_txt(txt, err_msg_auth_context_signin_user_ukjent_status_kode,         lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > unknown status code :: "};

    if (_is_txt(txt, err_msg_auth_update_token_400_error,               lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 400 :: "};
    if (_is_txt(txt, err_msg_auth_update_token_400_error,               lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 400 :: "};

    if (_is_txt(txt, err_msg_auth_update_token_401_error,               lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 401 :: "};
    if (_is_txt(txt, err_msg_auth_update_token_401_error,               lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 401 :: "};

    if (_is_txt(txt, err_msg_auth_update_token_404_error,               lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 404 :: "};
    if (_is_txt(txt, err_msg_auth_update_token_404_error,               lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_update_token > response > 404 :: "};

    if (_is_txt(txt, err_msg_auth_update_token_ukjent_status_kode,      lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_update_token > response > Ukjent status kode :: "};
    if (_is_txt(txt, err_msg_auth_update_token_ukjent_status_kode,      lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_update_token > response > Uncknown status code :: "};

    // Msg > Custom
    if (_is_txt(txt, err_msg_user_profil_redux_user_profil_feature_error,   lang, _eng))   {return "*** ERROR > redux_user_profil_feature.js > getUserProfil"};
    if (_is_txt(txt, err_msg_user_profil_redux_user_profil_feature_error,   lang, _no))    {return "*** ERROR > redux_user_profil_feature.js > getUserProfil"};

    if (_is_txt(txt, err_msg_redux_user_profil_feature_slice,       lang, _eng))   {return "*** ERROR > Redux > user_profil_feature > userProfilSlice"};
    if (_is_txt(txt, err_msg_redux_user_profil_feature_slice,       lang, _no))    {return "*** ERROR > Redux > user_profil_feature > userProfilSlice"};

    // Header > Par
    if (_is_txt(txt, json_tittel_root,              lang, _eng))        {return "Home"};
    if (_is_txt(txt, json_tittel_root,              lang, _no))         {return "Hjem"};

    if (_is_txt(txt, json_tittel_home,              lang, _eng))        {return "Home"};
    if (_is_txt(txt, json_tittel_home,              lang, _no))         {return "Hjem"};

    if (_is_txt(txt, "_json_User_Profil_List",      lang, _eng))        {return "User List"};
    if (_is_txt(txt, "_json_User_Profil_List",      lang, _no))         {return "Brukere"};

    if (_is_txt(txt, "_json_Profil",        lang, _eng))        {return "Profile"};
    if (_is_txt(txt, "_json_Profil",        lang, _no))         {return "Profil"};

    if (_is_txt(txt, "_json_Question",      lang, _eng))        {return "Questions"};
    if (_is_txt(txt, "_json_Question",      lang, _no))         {return "Spørsmål"}; // Spørsmål og programmer

    if (_is_txt(txt, "_json_The_Editor",    lang, _eng))        {return "Editor"};
    if (_is_txt(txt, "_json_The_Editor",    lang, _no))         {return "Editor"}; // Online-editor

    if (_is_txt(txt, "_json_Spill",         lang, _eng))        {return "Games"};
    if (_is_txt(txt, "_json_Spill",         lang, _no))         {return "Spill"};

    if (_is_txt(txt, "_json_Matte",         lang, _eng))        {return "Math"};
    if (_is_txt(txt, "_json_Matte",         lang, _no))         {return "Matte"};

    if (_is_txt(txt, "_json_Ganging",       lang, _eng))        {return "Multiplication"};
    if (_is_txt(txt, "_json_Ganging",       lang, _no))         {return "Ganging"};

    if (_is_txt(txt, "_json_Likning",       lang, _eng))        {return "Equation"};
    if (_is_txt(txt, "_json_Likning",       lang, _no))         {return "Likning"};

    if (_is_txt(txt, "_json_Lang",          lang, _eng))        {return "Programming language"};
    if (_is_txt(txt, "_json_Lang",          lang, _no))         {return "Programmering"}; // Programmeringsspråk

    if (_is_txt(txt, "_json_Fag",           lang, _eng))        {return "Subjects"};
    if (_is_txt(txt, "_json_Fag",           lang, _no))         {return "Fag"};

    if (_is_txt(txt, "_json_Admin",         lang, _eng))        {return "Administrator"};
    if (_is_txt(txt, "_json_Admin",         lang, _no))         {return "Admin"};

    if (_is_txt(txt, "_json_Brokmaskin",    lang, _eng))        {return "Brokmachine"};
    if (_is_txt(txt, "_json_Brokmaskin",    lang, _no))         {return "Brøkmaskin"};

    if (_is_txt(txt, "_json_Signout",       lang, _eng))        {return "Signout"};
    if (_is_txt(txt, "_json_Signout",       lang, _no))         {return "Logg ut"};

    if (_is_txt(txt, "_json_Signin",        lang, _eng))        {return "Signin"};
    if (_is_txt(txt, "_json_Signin",        lang, _no))         {return "Logg inn"};

    if (_is_txt(txt, "_json_Signup",        lang, _eng))        {return "Signup"};
    if (_is_txt(txt, "_json_Signup",        lang, _no))         {return "Registrer"};

    if (_is_txt(txt, "_json_About",         lang, _eng))        {return "*** About ***"};
    if (_is_txt(txt, "_json_About",         lang, _no))         {return "Om oss"};

    // Header > Ch > Lang
    if (_is_txt(txt, "_json_Python",        lang, _eng))        {return "Python"};
    if (_is_txt(txt, "_json_Python",        lang, _no))         {return "Python"};

    if (_is_txt(txt, "_json_JavaScript",    lang, _eng))        {return "JavaScript"};
    if (_is_txt(txt, "_json_JavaScript",    lang, _no))         {return "JavaScript"};

    if (_is_txt(txt, "_json_HTML",          lang, _eng))        {return "HTML"};
    if (_is_txt(txt, "_json_HTML",          lang, _no))         {return "HTML"};

    if (_is_txt(txt, "_json_CSS",           lang, _eng))        {return "CSS"};
    if (_is_txt(txt, "_json_CSS",           lang, _no))         {return "CSS"};

    if (_is_txt(txt, "_json_cpp",           lang, _eng))        {return "C++"};
    if (_is_txt(txt, "_json_cpp",           lang, _no))         {return "C++"};

    // Header > Ch > Fag
    if (_is_txt(txt, "_json_1P",            lang, _eng))        {return "1P"};
    if (_is_txt(txt, "_json_1P",            lang, _no))         {return "1P"};

    if (_is_txt(txt, "_json_1T",            lang, _eng))        {return "1T"};
    if (_is_txt(txt, "_json_1T",            lang, _no))         {return "1T"};

    if (_is_txt(txt, "_json_2P",            lang, _eng))        {return "2P"};
    if (_is_txt(txt, "_json_2P",            lang, _no))         {return "2P"};

    if (_is_txt(txt, "_json_S1",            lang, _eng))        {return "S1"};
    if (_is_txt(txt, "_json_S1",            lang, _no))         {return "S1"};

    if (_is_txt(txt, "_json_R1",            lang, _eng))        {return "R1"};
    if (_is_txt(txt, "_json_R1",            lang, _no))         {return "R1"};

    if (_is_txt(txt, "_json_S2",            lang, _eng))        {return "S2"};
    if (_is_txt(txt, "_json_S2",            lang, _no))         {return "S2"};

    if (_is_txt(txt, "_json_R2",            lang, _eng))        {return "R2"};
    if (_is_txt(txt, "_json_R2",            lang, _no))         {return "R2"};


    /* ****************************************** */
    /* Context */
    /* ****************************************** */

    if (_is_txt(txt, auth_msg_user_eksisterer_ikke_enda,            lang, _eng))   {return "> auth_context.js > fetch_await_update_token > User doesn`t exist yet"};
    if (_is_txt(txt, auth_msg_user_eksisterer_ikke_enda,            lang, _no))    {return "> auth_context.js > fetch_await_update_token > User eksisterer ikke enda"};


    /* ****************************************** */
    /* Pages */
    /* ****************************************** */

    if (_is_txt(txt, landing_special_case_home_tittel,      lang, _eng))   {return "Home"};
    if (_is_txt(txt, landing_special_case_home_tittel,      lang, _no))    {return "Hjem"};


    /* ****************************************** */
    /* Landing */
    /* ****************************************** */

    // Tittel
    if (_is_txt(txt, root_tittel,           lang, _eng))   {return "Root"};
    if (_is_txt(txt, root_tittel,           lang, _no))    {return "Root"};

    if (_is_txt(txt, landing_tittel,        lang, _eng))   {return "Landing"};
    if (_is_txt(txt, landing_tittel,        lang, _no))    {return "Landing"};

    if (_is_txt(txt, landing_tittel_sub,    lang, _eng))   {return "En nettside i utvikling"};
    if (_is_txt(txt, landing_tittel_sub,    lang, _no))    {return "A web page in process"};

    // Card 1 > Tittel
    if (_is_txt(txt, landing_tekst_mellom[0].tittel,   lang, _eng))     {return "Card 1"};
    if (_is_txt(txt, landing_tekst_mellom[0].tittel,   lang, _no))      {return "Kort 1"};

    if (_is_txt(txt, landing_tekst_mellom[1].tittel,   lang, _eng))     {return "Card 22"};
    if (_is_txt(txt, landing_tekst_mellom[1].tittel,   lang, _no))      {return "Kort 22"};

    if (_is_txt(txt, landing_tekst_mellom[2].tittel,   lang, _eng))     {return "Card 3"};
    if (_is_txt(txt, landing_tekst_mellom[2].tittel,   lang, _no))      {return "Kort 3"};

    if (_is_txt(txt, landing_tekst_mellom[3].tittel,   lang, _eng))     {return "Card 4"};
    if (_is_txt(txt, landing_tekst_mellom[3].tittel,   lang, _no))      {return "Kort 5"};

    // Card 1 > Content
    if (_is_txt(txt, landing_tekst_mellom[0].content,   lang, _eng))     {return "Info 1"};
    if (_is_txt(txt, landing_tekst_mellom[0].content,   lang, _no))      {return "Informasjon 1"};

    if (_is_txt(txt, landing_tekst_mellom[1].content,   lang, _eng))     {return "Info 2"};
    if (_is_txt(txt, landing_tekst_mellom[1].content,   lang, _no))      {return "Informasjon 2"};

    if (_is_txt(txt, landing_tekst_mellom[2].content,   lang, _eng))     {return "Info 3"};
    if (_is_txt(txt, landing_tekst_mellom[2].content,   lang, _no))      {return "Informasjon 3"};

    if (_is_txt(txt, landing_tekst_mellom[3].content,   lang, _eng))     {return "Info 4"};
    if (_is_txt(txt, landing_tekst_mellom[3].content,   lang, _no))      {return "Informasjon 4"};

    // Card 1 > Txt
    if (_is_txt(txt, landing_tekst_mellom[0].txt,   lang, _eng))     {return "Sub-txt 0 DUMMY"};
    if (_is_txt(txt, landing_tekst_mellom[0].txt,   lang, _no))      {return "SUB-txt 0 DUMMY"};

    if (_is_txt(txt, landing_tekst_mellom[1].txt,   lang, _eng))     {return "Sub-txt 1"};
    if (_is_txt(txt, landing_tekst_mellom[1].txt,   lang, _no))      {return "SUB-txt 1"};

    if (_is_txt(txt, landing_tekst_mellom[2].txt,   lang, _eng))     {return "Sub-txt 2"};
    if (_is_txt(txt, landing_tekst_mellom[2].txt,   lang, _no))      {return "SUB-txt 2"};

    if (_is_txt(txt, landing_tekst_mellom[3].txt,   lang, _eng))     {return "Sub-txt 3"};
    if (_is_txt(txt, landing_tekst_mellom[3].txt,   lang, _no))      {return "SUB-txt 3"};

    if (_is_txt(txt, landing_tekst_mellom[4].txt,   lang, _eng))     {return "Sub-txt 4"};
    if (_is_txt(txt, landing_tekst_mellom[4].txt,   lang, _no))      {return "SUB-txt 4"};


    /* ****************************************** */
    /* Mal */
    /* ****************************************** */

    if (_is_txt(txt, mal_tittel_hoved,          lang, _eng))   {return "TEMPLATE"};
    if (_is_txt(txt, mal_tittel_hoved,          lang, _no))    {return "MAL"};

    if (_is_txt(txt, mal_tittel_undertittel,    lang, _eng))   {return "Template!"};
    if (_is_txt(txt, mal_tittel_undertittel,    lang, _no))    {return "Mal!"};

    if (_is_txt(txt, mal_hello_val,             lang, _eng))   {return "Hello, api!"};
    if (_is_txt(txt, mal_hello_val,             lang, _no))    {return "Hei, api!"};


    /* ****************************************** */
    /* Admin */
    /* ****************************************** */

    // Admin > Ord
    if (txt === push_el_new_file)                   {return "Push el / new file"};
    if (txt === add_dir)                            {return "Add dir"};
    if (txt === remove_dir)                         {return "Remove dir"};

    // Admin > I
    if (txt === admin_tittel)                       {return "Admin"};
    if (txt === admin_db_tittel)                    {return "DB"};
    if (txt === admin_user_profil_disp_data)        {return "Display user profil data"};
    if (txt === admin_json_backend_cap)             {return "JSON BACKEND"};

    // Admin > II
    if (txt === admin_user_arr_tittel_cap)          {return "USR, USERPROFIL"};
    if (txt === admin_user_profil_disp_tittel_cap)  {return "USER PROFIL DISP"};
    if (txt === admin_email_developer_cap)          {return "DEVELOPER"};
    if (txt === admin_test_navigation_cap)          {return "TEST NAVIGATION"};
    if (txt === admin_test_navigation_btn)          {return "Test navigation"};


    /* ****************************************** */
    /* Reg */
    /* ****************************************** */

    // Reg > Sign > I
    if (_is_txt(txt, signup_tittel,         lang, _eng))   {return "Signup"};
    if (_is_txt(txt, signup_tittel,         lang, _no))    {return "Registrer"};

    if (_is_txt(txt, signup_allerede_reg,   lang, _eng))   {return "Already signed up? "};
    if (_is_txt(txt, signup_allerede_reg,   lang, _no))    {return "Allerede registrert? "};

    if (_is_txt(txt, signin_tittel,         lang, _eng))   {return "Sign in"};      // Alternativ: "Epost sendt"
    if (_is_txt(txt, signin_tittel,         lang, _no))    {return "Logg inn"};

    if (_is_txt(txt, signin_ny_prog,        lang, _eng))   {return "New on programmering.no? "}; // Lag dynamisk
    if (_is_txt(txt, signin_ny_prog,        lang, _no))    {return "Ny på programmering.no? "};

    // Reg > Sign > II
    if (_is_txt(txt, signin_glemt_pass,         lang, _eng))   {return "Forgotten password?"};
    if (_is_txt(txt, signin_glemt_pass,         lang, _no))    {return "Glemt passord?"};

    if (_is_txt(txt, signin_glemt_pass_tittel,  lang, _eng))   {return "Create new password"};
    if (_is_txt(txt, signin_glemt_pass_tittel,  lang, _no))    {return "Lag nytt passord"};

    if (_is_txt(txt, signin_glemt_pass_confirm,         lang, _eng))   {return "Email sent"}; // Alternativ: "Reset-epost sendt"
    if (_is_txt(txt, signin_glemt_pass_confirm,         lang, _no))    {return "Epost sendt"};

    if (_is_txt(txt, signin_glemt_pass_confirm_btn,     lang, _eng))   {return "Send passward reset link"};
    if (_is_txt(txt, signin_glemt_pass_confirm_btn,     lang, _no))    {return "Send passord reset-link"};

    if (_is_txt(txt, signin_glemt_pass_sett_nytt,       lang, _eng))   {return "Create new password";};
    if (_is_txt(txt, signin_glemt_pass_sett_nytt,       lang, _no))    {return "Sett nytt passord"};

    if (_is_txt(txt, signout_tittel,            lang, _eng))   {return "Signout"};
    if (_is_txt(txt, signout_tittel,            lang, _no))    {return "Logg ut"};

    if (_is_txt(txt, reg_ugyldig,               lang, _eng))   {return "Complete signup"};
    if (_is_txt(txt, reg_ugyldig,               lang, _no))    {return "Fullfør registreringen"};

    // Reg > Username
    if (_is_txt(txt, username_tittel,           lang, _eng))   {return "Username"};
    if (_is_txt(txt, username_tittel,           lang, _no))    {return "Brukernavn"};

    if (_is_txt(txt, username_sub_ikke_gyldig,  lang, _eng))   {return "Username is invalid"};      // Alternativ: "Reset-epost sendt"
    if (_is_txt(txt, username_sub_ikke_gyldig,  lang, _no))    {return "Brukernavn er ugyldig"};    // "Minst 6 tegn, ingen spesialtegn"

    if (_is_txt(txt, username_sub_ikke_gyldig_opptatt,  lang, _eng))   {return "Username is taken"};
    if (_is_txt(txt, username_sub_ikke_gyldig_opptatt,  lang, _no))    {return "Brukernavn er opptatt"};

    if (_is_txt(txt, username_sub_gyldig,       lang, _eng))   {return "Username is available";};
    if (_is_txt(txt, username_sub_gyldig,       lang, _no))    {return "Brukernavn er ledig"};

    if (_is_txt(txt, username_sub_gyldig_max,   lang, _eng))   {return "Username is available (max length)"};
    if (_is_txt(txt, username_sub_gyldig_max,   lang, _no))    {return "Brukernavn er ledig (max lengde)"};

    // Reg > Email
    if (_is_txt(txt, email_tittel,              lang, _eng))   {return "Email"};
    if (_is_txt(txt, email_tittel,              lang, _no))    {return "Epost"};

    if (_is_txt(txt, email_sub_ugyldig,         lang, _eng))   {return "Email is invalid";};
    if (_is_txt(txt, email_sub_ugyldig,         lang, _no))    {return "Epost er ugyldig"};

    if (_is_txt(txt, email_sub_gyldig,          lang, _eng))   {return "Email is valid";};
    if (_is_txt(txt, email_sub_gyldig,          lang, _no))    {return "Epost er gyldig"};

    if (_is_txt(txt, email_sub_gyldig_max,      lang, _eng))   {return "Email is valid (max lenth)"};
    if (_is_txt(txt, email_sub_gyldig_max,      lang, _no))    {return "Epost er gyldig (max lengde)"};

    if (_is_txt(txt, reg_email_send_user_confirm_developer_txt,     lang, _eng))   {return "user confirm email";};
    if (_is_txt(txt, reg_email_send_user_confirm_developer_txt,     lang, _no))    {return "user confirm-epost"};

    if (_is_txt(txt, reg_email_send_glemt_passord_developer_txt,    lang, _eng))   {return "forgotten password email"};
    if (_is_txt(txt, reg_email_send_glemt_passord_developer_txt,    lang, _no))    {return "glemt passord-epost"};

    // Password > I
    if (_is_txt(txt, password_tittel,           lang, _eng))   {return "Password"};
    if (_is_txt(txt, password_tittel,           lang, _no))    {return "Passord"};

    if (_is_txt(txt, password_sub_gyldig,       lang, _eng))   {return "Password matches"};
    if (_is_txt(txt, password_sub_gyldig,       lang, _no))    {return "Passord matcher"};

    if (_is_txt(txt, password_sub_ikke_gyldig,  lang, _eng))   {return "Password doesn´t match";};
    if (_is_txt(txt, password_sub_ikke_gyldig,  lang, _no))    {return "Passord matcher ikke"};

    if (_is_txt(txt, password_svakt,            lang, _eng))   {return "Weak (min 6 symbols)"};
    if (_is_txt(txt, password_svakt,            lang, _no))    {return "Svakt (minst 6 tegn)"};

    // Password > II
    if (_is_txt(txt, password_middels,          lang, _eng))   {return "Medium (min 6 symbols)"};
    if (_is_txt(txt, password_middels,          lang, _no))    {return "Middels (minst 6 tegn)"};

    if (_is_txt(txt, password_sterkt,           lang, _eng))   {return "Strong"};
    if (_is_txt(txt, password_sterkt,           lang, _no))    {return "Sterkt"};

    if (_is_txt(txt, password_max_lengde,       lang, _eng))   {return "Strong (max length)"};
    if (_is_txt(txt, password_max_lengde,       lang, _no))    {return "Sterkt (max lengde)"};

    if (_is_txt(txt, password_for_langt,        lang, _eng))   {return "Too long"};
    if (_is_txt(txt, password_for_langt,        lang, _no))    {return "For langt"};


    /* ****************************************** */
    /* User profil */
    /* ****************************************** */

    // User profil > Disp > I
    if (_is_txt(txt, user_profil_tittel,                    lang, _eng))   {return "Profile"};
    if (_is_txt(txt, user_profil_tittel,                    lang, _no))    {return "Profil"};

    if (_is_txt(txt, user_profil_img_profil_tittel_txt,     lang, _eng))   {return "Profile image"};
    if (_is_txt(txt, user_profil_img_profil_tittel_txt,     lang, _no))    {return "Profilbilde"};

    if (_is_txt(txt, user_profil_navn,                      lang, _eng))   {return "Name"};
    if (_is_txt(txt, user_profil_navn,                      lang, _no))    {return "Navn"};

    if (_is_txt(txt, user_profil_navn_placeholder,          lang, _eng))   {return "My name"};
    if (_is_txt(txt, user_profil_navn_placeholder,          lang, _no))    {return "Mitt navn"};

    if (_is_txt(txt, user_profil_public_email,              lang, _eng))   {return "Public email"};
    if (_is_txt(txt, user_profil_public_email,              lang, _no))    {return "Offentlig epost"};

    if (_is_txt(txt, user_profil_public_email_placeholder,  lang, _eng))   {return "My public email"};
    if (_is_txt(txt, user_profil_public_email_placeholder,  lang, _no))    {return "Min offentlige epost"};

    // User profil > Disp > II
    if (_is_txt(txt, user_profil_bio,                       lang, _eng))   {return "Biography"};
    if (_is_txt(txt, user_profil_bio,                       lang, _no))    {return "Biografi"};

    if (_is_txt(txt, user_profil_bio_placeholder,           lang, _eng))   {return "About yourself"};
    if (_is_txt(txt, user_profil_bio_placeholder,           lang, _no))    {return "Om deg selv"};

    if (_is_txt(txt, user_profil_url_public,                lang, _eng))   {return "Web page"};
    if (_is_txt(txt, user_profil_url_public,                lang, _no))    {return "Nettside"};

    if (_is_txt(txt, user_profil_url_public_placeholder,    lang, _eng))   {return "Link to web page"};
    if (_is_txt(txt, user_profil_url_public_placeholder,    lang, _no))    {return "Link til nettside"};

    if (_is_txt(txt, user_profil_company,                   lang, _eng))   {return "Company"};
    if (_is_txt(txt, user_profil_company,                   lang, _no))    {return "Selskap"};

    if (_is_txt(txt, user_profil_company_placeholder,       lang, _eng))   {return "Employer"};
    if (_is_txt(txt, user_profil_company_placeholder,       lang, _no))    {return "Hvem jobber du for?"};

    // User profil > Account > Set > I
    if (_is_txt(txt, user_profil_account_tittel,            lang, _eng))   {return "Your account"};
    if (_is_txt(txt, user_profil_account_tittel,            lang, _no))    {return "Din brukerkonto"};

    if (_is_txt(txt, user_profil_account_konto_gruppe,      lang, _eng))   {return "ACCOUNT"};
    if (_is_txt(txt, user_profil_account_konto_gruppe,      lang, _no))    {return "KONTO"};

    if (_is_txt(txt, user_profil_account_item_public_profil_tittel,     lang, _eng))   {return "Profile"};
    if (_is_txt(txt, user_profil_account_item_public_profil_tittel,     lang, _no))    {return "Profil"};

    if (_is_txt(txt, user_profil_account_item_account_tittel,           lang, _eng))   {return "Username and password"};
    if (_is_txt(txt, user_profil_account_item_account_tittel,           lang, _no))    {return "Brukernavn og passord"};

    if (_is_txt(txt, user_profil_account_item_lang_tittel,  lang, _eng))   {return "Language"};
    if (_is_txt(txt, user_profil_account_item_lang_tittel,  lang, _no))    {return "Språk"};

    // User profil > Account > Set > II
    if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_success_confirm,     lang, _eng))   {return "Password changed"};
    if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_success_confirm,     lang, _no))    {return "Passord endret"};

    if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_fail_confirm,        lang, _eng))   {return "Ooops! Something went wrong, password was not changed"};
    if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_fail_confirm,        lang, _no))    {return "Ooops! Noe gikk galt, passordet ble ikke endret"};

    if (_is_txt(txt, user_profil_account_item_notifications_tittel,     lang, _eng))   {return "Notifications"};
    if (_is_txt(txt, user_profil_account_item_notifications_tittel,     lang, _no))    {return "Notifikasjoner"};

    if (_is_txt(txt, user_profil_account_item_email_tittel,             lang, _eng))   {return "Email"};
    if (_is_txt(txt, user_profil_account_item_email_tittel,             lang, _no))    {return "Epost"};

    // User profil > Account > Set > Konto > Username
    if (_is_txt(txt, usernavn_nytt_tittel,                  lang, _eng))   {return "New username"};
    if (_is_txt(txt, usernavn_nytt_tittel,                  lang, _no))    {return "Nytt brukernavn"};

    if (_is_txt(txt, usernavn_nytt_btn_endre,               lang, _eng))   {return "Change username"};
    if (_is_txt(txt, usernavn_nytt_btn_endre,               lang, _no))    {return "Endre brukernavn"};

    if (_is_txt(txt, usernavn_nytt_btn_update,              lang, _eng))   {return "Update username"};
    if (_is_txt(txt, usernavn_nytt_btn_update,              lang, _no))    {return "Oppdater brukernavn"};

    // User profil > Account > Set > Konto > Password
    if (_is_txt(txt, password_nytt_tittel,                  lang, _eng))   {return "New password"};
    if (_is_txt(txt, password_nytt_tittel,                  lang, _no))    {return "Nytt passord"};

    if (_is_txt(txt, password_nytt_btn_endre,               lang, _eng))   {return "Change password"};
    if (_is_txt(txt, password_nytt_btn_endre,               lang, _no))    {return "Endre passord"};

    if (_is_txt(txt, password_nytt_btn_update,              lang, _eng))   {return "Update password"};
    if (_is_txt(txt, password_nytt_btn_update,              lang, _no))    {return "Oppdater passord"};

    if (_is_txt(txt, password_dum,                          lang, _eng))   {return "12345678"};
    if (_is_txt(txt, password_dum,                          lang, _no))    {return "12345678"};

    if (_is_txt(txt, password_bekreft_tittel,               lang, _eng))   {return "Confirm password"};
    if (_is_txt(txt, password_bekreft_tittel,               lang, _no))    {return "Bekreft passord"};


    /* ****************************************** */
    /* Footer Page */
    /* ****************************************** */

    if (_is_txt(txt, footer_copyright,          lang, _eng))   {return `© 2023 ${nettside}, ${selskap_as}`};
    if (_is_txt(txt, footer_copyright,          lang, _no))    {return `© 2023 ${nettside}, ${selskap_as}`};


    if (_is_txt(txt, footer_about_tittel,       lang, _eng))   {return "About"};
    if (_is_txt(txt, footer_about_tittel,       lang, _no))    {return "Om oss"};

    if (_is_txt(txt, footer_kurs_tittel,        lang, _eng))   {return "Courses"};
    if (_is_txt(txt, footer_kurs_tittel,        lang, _no))    {return "Kurs"};

    if (_is_txt(txt, footer_terms_tittel,       lang, _eng))   {return "Terms"};
    if (_is_txt(txt, footer_terms_tittel,       lang, _no))    {return "Vilkår"};


    if (_is_txt(txt, footer_about_tittel_sub,   lang, _eng))   {return "Footer-info"};
    if (_is_txt(txt, footer_about_tittel_sub,   lang, _no))    {return "Footer-informasjon"};

    if (_is_txt(txt, footer_about_tittel_sub,   lang, _eng))    {return "Footer-infotion"};
    if (_is_txt(txt, footer_about_tittel_sub,   lang, _no))     {return "Footer-informasjon"};


    /* ****************************************** */
    /* footer.js-component > Nederst på hver side */
    /* ****************************************** */

    if (_is_txt(txt, footer_copyright,          lang, _eng))   {return `© 2023 ${nettside}, ${selskap_as}`};
    if (_is_txt(txt, footer_copyright,          lang, _no))    {return `© 2023 ${nettside}, ${selskap_as}`};



    /* ****************************************** */
    /* Catch all default */
    /* ****************************************** */

    return txt

};

// Emo > Ingen human lang
const emo_root                                  = "🚀";             // 🏠 ("\ud83c\udfe0");
const emo_admin                                 = "🚧";
const emo_profil_disp                           = "🧑‍🚀";             // "👤";
const emo_profil_set                            = "\u2699\uFE0F";   // For liten: "⚙";


/* ****************************************** */
/* Export /*
/* ****************************************** */

export {
    // Get txt
    get_txt,

    // Debug
    txt_test,
    versjon,

    // Alert > DEV rydd de andre alerts her
    email_confirm_alert,

    // Footer
    footer_copyright,
    footer_about_tittel,
    footer_kurs_tittel,
    footer_terms_tittel,
    footer_about_tittel_sub,

    // Textarea
    bokstaver_igjen,
    bokstav_igjen,

    // Ord
    push_el_new_file,
    add_dir,
    remove_dir,

    // Btn
    upload_bilde_btn,
    update_btn,
    update_profil_btn,
    update_profil_bilde_btn,
    delete_btn,
    complete_btn,
    cancel_btn,
    load_btn,
    dump_btn,
    cycle_btn,
    cleanup_bu_btn,
    remove_btn,
    add_btn,
    push_btn,
    mal_many_btn,

    // Cropper
    crop_bilde,

    // Auth context
    auth_msg_user_eksisterer_ikke_enda,

    // Admin
    admin_tittel,
    admin_db_tittel,
    admin_user_profil_disp_data,
    admin_json_backend_cap,
    admin_user_profil_disp_tittel_cap,
    admin_user_arr_tittel_cap,
    admin_email_developer_cap,
    admin_test_navigation_cap,
    admin_test_navigation_btn,

    // Reg
    signup_tittel,
    signup_allerede_reg,
    signin_tittel,
    signin_ny_prog,
    signin_wrong_password_alert,
    signin_glemt_pass,
    signin_glemt_pass_tittel,
    signin_glemt_pass_alert,
    signin_glemt_pass_confirm,
    signin_glemt_pass_confirm_btn,
    signin_glemt_pass_confirm_info_1_alert,
    signin_glemt_pass_confirm_info_2_alert,
    signin_glemt_pass_sett_nytt,
    signout_tittel,
    reg_ugyldig,
    username_tittel,
    username_sub_ikke_gyldig,
    username_sub_ikke_gyldig_opptatt,
    username_sub_gyldig,
    username_sub_gyldig_max,
    username_updated_signin_again_alert,
    email_tittel,
    email_sub_ugyldig,
    email_sub_gyldig,
    email_sub_gyldig_max,
    password_tittel,
    password_sub_gyldig,
    password_sub_ikke_gyldig,
    password_svakt,
    password_middels,
    password_sterkt,
    password_max_lengde,
    password_for_langt,
    reg_email_send_user_confirm_developer_txt,
    reg_email_send_glemt_passord_developer_txt,


    /* ****************************************** */
    /* Pages /*
    /* ****************************************** */

    // Root, landing
    root_tittel,
    landing_tittel,
    landing_tittel_sub,

    // Root, Landing > Obj
    landing_tekst_mellom,

    // User profil > Disp
    user_profil_tittel,
    user_profil_img_profil_tittel_txt,
    user_profil_navn,
    user_profil_navn_placeholder,
    user_profil_public_email,
    user_profil_public_email_placeholder,
    user_profil_bio,
    user_profil_bio_placeholder,
    user_profil_url_public,
    user_profil_url_public_placeholder,
    user_profil_company,
    user_profil_company_placeholder,

    // User profil (account) > Set
    user_profil_account_tittel,
    user_profil_account_konto_gruppe,
    user_profil_account_item_public_profil_tittel,
    user_profil_account_item_account_tittel,
    user_profil_account_item_lang_tittel,
    user_profil_account_item_account_passord_endret_alert_success_confirm,
    user_profil_account_item_account_passord_endret_alert_fail_confirm,
    user_profil_account_item_notifications_tittel,
    user_profil_account_item_email_tittel,

    // User profil (account) > Set > Konto
    usernavn_nytt_tittel,
    usernavn_nytt_btn_endre,
    usernavn_nytt_btn_update,
    password_dum,
    password_nytt_tittel,
    password_nytt_btn_endre,
    password_nytt_btn_update,
    password_bekreft_tittel,

    // Header
    header_developer_tittel,

    // Page
    landing_special_case_home_tittel,

    // Mal
    mal_tittel_hoved,
    mal_tittel_undertittel,
    mal_hello_val,

    // Emo
    emo_root,
    emo_admin,
    emo_profil_disp,
    emo_profil_set,

    // Human lang
    eng_tittel,
    no_tittel,

    // Error > Status code
    err_msg_auth_context_signin_user_400_error,
    err_msg_auth_context_signin_user_401_error,
    err_msg_auth_context_signin_user_404_error,
    err_msg_auth_context_signin_user_405_error,
    err_msg_auth_context_signin_user_ukjent_status_kode,
    err_msg_auth_update_token_400_error,
    err_msg_auth_update_token_401_error,
    err_msg_auth_update_token_404_error,
    err_msg_auth_update_token_ukjent_status_kode,

    // Error > Custom
    err_msg_user_profil_redux_user_profil_feature_error,
    err_msg_redux_user_profil_feature_slice
}


/* ****************************************** */
/* End /*
/* ****************************************** */
