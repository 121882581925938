/* ****************************************** */
/* Group */
/* ****************************************** */

// Group > Txt
const grp_txt_norm_grey                     = "grp_txt_norm_grey";              // grp-variablene nedenfor kommer hovedsaklig i den reggek-følgen de har i group_util.js
const grp_txt_norm_beige_hoverable          = "grp_txt_norm_beige_hoverable";
const grp_txt_norm_link                     = "grp_txt_norm_link";
const grp_txt_medium_grey_bold              = "grp_txt_medium_grey_bold";
const grp_txt_stor_beige                    = "grp_txt_stor_beige";
const grp_txt_felt_modal_beige              = "grp_txt_felt_modal_beige";

// Group > Icon > Size
const grp_icon_liten_24_16                  = "liten_24_16";
const grp_icon_norm_24_24                   = "norm_24_24";
const grp_icon_norm_48_12                   = "norm_48_12";
const grp_icon_norm_48_16                   = "norm_48_16";
const grp_icon_norm_48_20                   = "norm_48_20";
const grp_icon_tittel_norm                  = "norm_tittel";
const grp_icon_forside_stor                 = "stor_forside";

// Group > Alert
const grp_alert_red_abs                     = "grp_alert_red_abs";
const grp_alert_green_100                   = "grp_alert_green_100";
const grp_alert_red_100                     = "grp_alert_red_100";
const grp_alert_green_reg                   = "grp_alert_green_reg";
const grp_alert_red_reg                     = "grp_alert_red_reg";

// Group > Img > Size
const grp_img_sub_bilde_profil              = "profil";
const grp_img_mini                          = "grp_img_mini";       // Ubrukt til Matacy-backend
const grp_img_mikro                         = "grp_img_mikro";
const grp_img_liten                         = "grp_img_liten";
const grp_img_norm                          = "grp_img_norm";
const grp_img_medium                        = "grp_img_medium";
const grp_img_stor                          = "grp_img_stor";

// Group > Btn
const grp_btn_green                         = "grp_btn_green";
const grp_btn_green_100                     = "grp_btn_green_100";

const grp_btn_red                           = "grp_btn_red";                        // DEV: Sjekk om avbryt_btn skal være grey btn (som i user profil_set)
const grp_btn_red_100                       = "grp_btn_red_100";
const grp_btn_red_trans_ugyldig             = "grp_btn_red_trans_ugyldig";
const grp_btn_red_trans_100_ikke_gyldig     = "grp_btn_red_trans_100_ikke_gyldig";

const grp_btn_blue_100                      = "grp_btn_blue_100";

const grp_btn_grey                          = "grp_btn_grey";
const grp_btn_grey_100                      = "grp_btn_grey_100";

const grp_btn_trans_white                   = "grp_btn_trans_white";

// Group > Input
const grp_input_felt                        = "grp_input_felt";
const grp_input_display_none                = "grp_input_display_none";

// Group > Felt
const grp_felt_100                          = "grp_felt_100";

const grp_felt_center                       = "grp_felt_center";
const grp_felt_left                         = "grp_felt_left";


export {

    // Group > Txt
    grp_txt_norm_beige_hoverable,
    grp_txt_norm_grey,
    grp_txt_norm_link,
    grp_txt_medium_grey_bold,
    grp_txt_stor_beige,
    grp_txt_felt_modal_beige,

    // Group > Icon > Size
    grp_icon_liten_24_16,
    grp_icon_norm_24_24,
    grp_icon_norm_48_12,
    grp_icon_norm_48_16,
    grp_icon_norm_48_20,
    grp_icon_tittel_norm,
    grp_icon_forside_stor,

    // Group > Alert
    grp_alert_red_abs,
    grp_alert_green_100,
    grp_alert_red_100,
    grp_alert_green_reg,
    grp_alert_red_reg,

    // Group > Img
    grp_img_sub_bilde_profil,
    grp_img_mini,
    grp_img_mikro,
    grp_img_liten,
    grp_img_norm,
    grp_img_medium,
    grp_img_stor,

    // Group > Btn
    grp_btn_green,
    grp_btn_green_100,
    grp_btn_red,
    grp_btn_red_100,
    grp_btn_red_trans_ugyldig,
    grp_btn_red_trans_100_ikke_gyldig,
    grp_btn_blue_100,
    grp_btn_grey,
    grp_btn_grey_100,
    grp_btn_trans_white,

    // Group > Input
    grp_input_felt,
    grp_input_display_none,

    // Group > Felt
    grp_felt_100,
    grp_felt_center,
    grp_felt_left,

};