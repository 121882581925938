/* ****************************************** */
/* Key */
/* ****************************************** */

// Mal > API
const key_mal                               = "mal";

// account > UsrModel > Model
const key_username                          = "username";

// account > UserProfil > Model
const key_img_profil                        = "img_profil";

// doc > Blokk > API
const key_blokk_arr                         = "blokk_arr";
const key_ch_arr                            = "ch_arr";
const key_open                              = "open";

// doc > Doc > API
const key_page                              = "page";


export {

    key_mal,

    key_username,

    key_img_profil,

    key_blokk_arr,
    key_ch_arr,
    key_open,

    key_page,

};