import {
    useCallback,
    useEffect
}                                                   from "react";
import {
    useDispatch,
    useSelector
}                                                   from "react-redux";
import {
    useNavigate
}                                                   from "react-router-dom";

import {
    doc_ingen_blokk_router
}                                                   from "../../konstanter/pages";
import {
    redux_doc
}                                                   from "../../konstanter/redux";
import {
    blank_str
}                                                   from "../../konstanter/str";

import {
    setDocFetch
}                                                   from "./redux_doc_feature";


const DoNavigate                                    = () => {

    // Navigate
    const navigate                                  = useNavigate();

    // Redux
    const dispatch                                  = useDispatch();

    // Doc
    const {blokk_selected_index}                    = useSelector( (store) => {return store[redux_doc]});
    const {blokk_link}                              = useSelector( (store) => {return store[redux_doc]});
    const {doc_slug}                                = useSelector( (store) => {return store[redux_doc]});
    const {url_frontend_route}                      = useSelector( (store) => {return store[redux_doc]});
    const set_doc_fetch                             = useCallback( (val) => {dispatch(setDocFetch(val))}, [dispatch]);

    // Do_navigating > Standard
    const _do_navigate_standard                     = useCallback( () => {

        // Navigate
        navigate(url_frontend_route);

        // Page > Ingen doc > Blokk string > Byass
        if (doc_slug === blank_str)   {}; // Bypass

        // Doc > Fetch
        if (doc_slug !== blank_str)   {set_doc_fetch(true);}

        // Dep
    }, [navigate,
        set_doc_fetch,
        url_frontend_route,
        doc_slug])

    // Do_navigating > Link
    const _do_navigate_link                         = useCallback( () => {

        // Navigate
        navigate(url_frontend_route);

        // Page > Ingen doc > Blokk string > Byass
        if (doc_slug === blank_str)   {}; // Bypass

        // Doc > Fetch
        if (doc_slug !== blank_str)   {set_doc_fetch(true);}

        // Dep
    }, [navigate,
        set_doc_fetch,
        url_frontend_route,
        doc_slug])

    // useEffect
    useEffect( () => { // console.log("> doNavigate.js > useEffect > blokk_link :: ", blokk_link)

        // 1. Ingen blokk link > Do_navigating > Standard
        if (blokk_link === blank_str && blokk_selected_index !== doc_ingen_blokk_router)        {_do_navigate_standard();};

        // 2. Blokk link > Bli på samme side i nytt browser-vindu > Bypass
        if (blokk_link !== blank_str && blokk_selected_index !== doc_ingen_blokk_router)        {}; // Bypass

        // 3. Do_navigating link > Brukes kun for link i nytt browser-vindu
        if (blokk_selected_index === doc_ingen_blokk_router)                                    {_do_navigate_link();};

        // Dep
    }, [_do_navigate_standard,
        _do_navigate_link,
        blokk_selected_index,
        blokk_link,
        doc_slug]);

    // Return
    return (<>{/* <div className="tekst">Debug :: </div> */}</>)

};


export default DoNavigate;
