import {
    auth_guest
}                                                   from "../konstanter/context";


const username_status                               = (user) => {

    // Init
    let _username = "";
    let _is_guest = -1;

    /* User > Post username > For tmp kode-dir */
    if (user) {
        _username = user.username;
        _is_guest = 0;}

    /* Ikke user > Username guest */
    else {
        _username = auth_guest;
        _is_guest = 1;};


    return [_username, _is_guest]

};


export {
    username_status
};
