/* ****************************************** */
/* HTTP */
/* ****************************************** */

// Method
const http_method_post                      = "POST";
const http_method_get                       = "GET";
const http_method_put                       = "PUT";
const http_method_delete                    = "DELETE";

// Content-Type
const http_headers_content_type_application_json = {
    "Content-Type": "application/json"
};

// Response
const http_response_ok                      = "http_response_ok";
const http_response_fail                    = "http_response_fail"

// Status code
const http_status_code_200                  = 200;
const http_status_code_400                  = 400;
const http_status_code_401                  = 401;
const http_status_code_404                  = 404;
const http_status_code_405                  = 405;


export {

    // Method
    http_method_post,
    http_method_get,
    http_method_put,
    http_method_delete,

    // Content-Type
    http_headers_content_type_application_json,

    // Response
    http_response_ok,
    http_response_fail,

    // Status code
    http_status_code_200,
    http_status_code_400,
    http_status_code_401,
    http_status_code_404,
    http_status_code_405,

};